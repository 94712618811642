import styled from "styled-components"
import { Input as InputBase } from "../../../Input"
import { Button as ButtonBase } from "../../../Button"
import { CommonParagraph } from "../../../Texts"

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Input = styled(InputBase)`
  width: 50%;
  box-sizing: border-box;
  &.mobile {
    width: 100%;
  }
`

export const SelectCountry = styled.select`
  width: 50%;
  box-sizing: border-box;
  &.mobile {
    width: 100%;
  }
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: 20px;
  margin: 10px;
`

export const Newsletter = styled.input`
  margin-right: 10px;
`

export const Checkbox = styled.div`
  margin-left: 0.5%;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Submit = styled(ButtonBase)`
  margin-top: 5%;
`

export const Wrappers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  &.mobile {
    width: 90%;
  }
`
export const LabelFormResult = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ${({ theme }) => theme.palette.primary.main}; */
`
export const ListErrors = styled(LabelFormResult)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FormSent = styled(CommonParagraph)`
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
`

export const ErrorFormSent = styled(FormSent)`
  color: ${({ theme }) => theme.palette.error.main};
`
export const Error = styled(ErrorFormSent)`
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
`
export const ContainerLabelAccount = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
`
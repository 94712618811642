import React, { useEffect, useRef, useState } from "react"
import { useCheckout } from "@saleor/sdk"

import {
  SelectorWrapper,
  FormWrapper,
  MapORFormDomicileArea,
  RadioButton,
  StyledFormDomicile,
  StyledFormAfficherMap,
  Title,
} from "./FormulaireLivraison.style"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { FormAfficherMap } from "./FormAfficherMap"
import { CustomerShippingAddresses } from "./CustomerShippingAddresses"
import { v4 as uuidv4 } from "uuid"
import { IAvailableShippingMethods } from "@saleor/sdk/lib/api/Checkout/types"
import { Components } from "react-markdown/src/ast-to-react"

interface PropFormAdresse {
  setIsPointRelais: Function
  isPointRelais: boolean
  shippingAsBilling: boolean
  children: Components
}

export const FormAdresse = ({
  shippingAsBilling,
  isPointRelais,
  setIsPointRelais,
  children,
}: PropFormAdresse) => {
  const { availableShippingMethods, setShippingMethod, checkout } =
    useCheckout()

  const isMobile = useDetectDevice()
  shippingAsBilling = false
  const [adressOrMapVisible, setAdressOrMapVisible] = useState(false)

  useEffect(() => {
    if (!adressOrMapVisible && checkout?.shippingMethod) {
      onChange(checkout.shippingMethod)
    }
  }, [checkout])

  const onChange = item => {
    if (
      item.name == "FRANCE COLISSIMO RELAIS" ||
      item.name == "Livraison en point relais"
    ) {
      setIsPointRelais(true)
    } else {
      setIsPointRelais(false)
    }
    setShippingMethod(item.id)
    setAdressOrMapVisible(true)
  }

  return (
    <>
      <FormWrapper className={isMobile && "mobile"}>
        <Title className={isMobile && "mobile"}>
          Veuillez choisir votre mode de livraison
        </Title>
        <SelectorWrapper className={isMobile && "mobile"}>
          <ShippingMethodSelector
            isInit={adressOrMapVisible}
            availableShippingMethods={availableShippingMethods}
            onChange={onChange}
          ></ShippingMethodSelector>
        </SelectorWrapper>
        {/* {!adressOrMapVisible && (<CustomerShippingAddresses
        shippingAsBilling={shippingAsBilling}
      />)} */}
        {adressOrMapVisible && (
          <MapORFormDomicileArea>
            {!isPointRelais && (
              <CustomerShippingAddresses
                shippingAsBilling={shippingAsBilling}
              />
            )}
            {isPointRelais && (
              <StyledFormAfficherMap>
                <FormAfficherMap postalCode={""} />
              </StyledFormAfficherMap>
            )}
          </MapORFormDomicileArea>
        )}
      </FormWrapper>
      <>{adressOrMapVisible && children}</>
    </>
  )
}

export const getAvailableShippingMethodsFiltered = (
  availableShippingMethods: IAvailableShippingMethods
) => {
  const getMinValue = (group, minValue) => {
    group.forEach(element => {
      if (!minValue) {
        minValue = element
      } else if (minValue.price.amount > element.price.amount) {
        minValue = element
      }
    })
    return minValue
  }

  const groups = availableShippingMethods.reduce((groups, item) => {
    let { group, min } = groups[item.name] || { group: [], min: null }
    group.push(item)
    groups[item.name] = { group: group, min: getMinValue(group, min) }
    return groups
  }, {})

  let availableShippingMethodsFiltered: IAvailableShippingMethods = Object.keys(
    groups
  ).map(key => groups[key].min)
  return availableShippingMethodsFiltered
}

interface propsShippingMethodSelector {
  isInit: boolean
  availableShippingMethods: IAvailableShippingMethods
  onChange: Function
}

function ShippingMethodSelector({
  isInit,
  availableShippingMethods,
  onChange,
}: propsShippingMethodSelector) {
  const { checkout } = useCheckout()

  const availableShippingMethodsFiltered = getAvailableShippingMethodsFiltered(
    availableShippingMethods
  ) as IAvailableShippingMethods
  if (
    !availableShippingMethodsFiltered ||
    availableShippingMethodsFiltered?.length <= 0
  )
    return <></>

  return availableShippingMethodsFiltered?.map((item, index) => {
    return (
      <RadioButton
        key={uuidv4()}
        name="shippingMethod"
        type="button"
        checked={isInit && checkout?.shippingMethod?.id == item.id}
        value={item.id}
        onClick={() => onChange(item)}
      >
        {item.name}
      </RadioButton>
    )
  })
}

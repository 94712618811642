import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-apollo"
import { RESET_PASSWORD } from "./DemandNewPassword.query"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import {
  Error,
  Input,
  Submit,
  Valid,
} from "./DemandNewPassword.style"

interface IFormInput {
  email: string
}

export function DemandNewPasswordView() {
  const [ResetPassword] = useMutation(RESET_PASSWORD)
  const [customError, setCustomError] = useState("")
  const [customSuccess, setCustomSuccess] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>()
  const isMobile = useDetectDevice()
  const classMobile=isMobile?"mobile":""

  const onSubmit = async (data: IFormInput) => {
    setCustomError("")
    setCustomSuccess("")
    try {
      await ResetPassword({
        variables: {
          email: data.email,
          redirectUrl: process.env.GATSBY_FRONTEND_URL+"/app/reset-password"
        },
      })
      setCustomSuccess("Email envoyé, veuillez consulter votre boîte de réception ")
    } catch (error) {
      setCustomError("Une erreur est survenue.")
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        className={classMobile}
        placeholder="Email"
        type="email"
        {...register("email", {
          required: true,
        })}
      />
      <Submit type="submit">VALIDER</Submit>
      {errors.email && <Error>Champs incorrect</Error>}
      {customError && <Error>{customError}</Error>}
      {customSuccess && <Valid>{customSuccess}</Valid>}
    </form>
  )
}
import { IStepInfo } from "../core/config"

export function getActiveStep(stepsList: IStepInfo[]): IStepInfo {
  return (
    stepsList.filter(items => {
      const path = location.pathname.replace("/app", "")
      return items.link.includes(path)
    })[0] || stepsList[0]
  )
}

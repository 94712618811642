import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { PointRelais } from "../../../../../../../lib/map"
import {
  Wrapper,
  Title,
  Address,
  Horaires,
  Test,
  WrapperClicked,
  Test1,
  Test2,
  Icon,
  SpaceOnText,
} from "./ItemPointRelais.style"
import InfiniteScroll from "react-infinite-scroll-component"
import { store } from "../../../../../../../store/configure"

interface Props {
  className?: string
  data: PointRelais
  setReduxState: (id: string, pointRelais: any) => void
}

export const ItemPointRelaisView = React.memo(
  ({ className, data, setReduxState }: Props) => {
    const { selected: selectedRedux } = useSelector(
      state => state.activePointLivraisonReducer
    )
    const [isOpen, setIsOpen] = useState(false)
    const [selectedState, setIsSelected] = React.useState(
      selectedRedux == data.IdPointRelais ? true : false
    )

    const clickHandler = () => {
      setReduxState(data.IdPointRelais, data)
    }

    useEffect(() => {
      if (selectedRedux == data.IdPointRelais) {
        setIsSelected(true)
      }

      if (selectedRedux != data.IdPointRelais) {
        setIsSelected(false)
      }
    }, [selectedRedux])

    const handleShow = (e: event) => {
      e.preventDefault()
      e.stopPropagation()
      setIsOpen(!isOpen)
    }

    return (
      <>
        {!selectedState && (
          <Wrapper
            className={className + " " + data.IdPointRelais}
            onClick={() => {
              clickHandler()
            }}
          >
            <Title>{data.Name}</Title>
            <Address>{data.Adress} </Address>
            <Test onClick={handleShow}> Afficher les horaires</Test>
            {isOpen && (
              <Horaires>
                {data.OpeningHours.map(x => (
                  <div>{x}</div>
                ))}
              </Horaires>
            )}
          </Wrapper>
        )}
        {selectedState && (
          <WrapperClicked
            className={className + " " + data.IdPointRelais}
            onClick={() => {
              clickHandler()
            }}
          >
            <Title>{data.Name}</Title>
            <Address>{data.Adress} </Address>
            <Icon icon="check-circle" />
            <Horaires>
              {data.OpeningHours.map(x => (
                <div>{x}</div>
              ))}
            </Horaires>
          </WrapperClicked>
        )}
      </>
    )
  }
)

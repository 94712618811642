import { CheckoutStep, CHECKOUT_STEPS, IStepInfo } from "../core/config"

export function getNextStep(
  currentStep: IStepInfo,
  stepsList: IStepInfo[]
): IStepInfo | undefined {
  const nextStepIndex = currentStep.index + 1
  return stepsList.length > nextStepIndex ? stepsList[nextStepIndex] : undefined
}

export function getNextStepFromEnum(
  stepEnu: CheckoutStep
): IStepInfo | undefined {
  const [currentStep] = CHECKOUT_STEPS.filter(
    (item: IStepInfo) => item.step === stepEnu
  )
  const nextStepIndex = currentStep.index + 1
  return CHECKOUT_STEPS.length > nextStepIndex
    ? CHECKOUT_STEPS[nextStepIndex]
    : undefined
}

import styled from "styled-components"
import { CommonSubtitle } from "../../../../common/Texts"
import { StyledMapContainer } from "./MapPointRelais/ContentMap/Map.style"
import { Horaires } from "./PanelPointRelais/ItemPointRelais/ItemPointRelais.style"

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  @media (max-width: 1350px) {
    ${StyledMapContainer} {
      height: 200px;
    }

    ${Horaires} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      div {
        white-space: nowrap;
        margin-right: 5px;
      }
    }
  }
`

export const WrapperMap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 800px;
  height: 400px;
  &.mobile{
    width: auto;
  }
`

export const Test1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 18em;
`
export const Test3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1%;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.info.fontSize};
  font-size: 1.5rem;
  margin-bottom: 2%;
`

export const Label = styled(CommonSubtitle)`
  font-size: 20px;
`

export const Test4 = styled.div`
  height: 300px;
`

export const WrapperPanelMap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  &.mobile{
    flex-direction: column-reverse;
  }
`

import styled from "styled-components"

export const Wrapper = styled.div`
  box-sizing:border-box;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 450px;
  overflow: auto;
  border: #d9d9d9 solid 1px;
`

export const Wrapper2 = styled.div`
box-sizing:border-box;
height:18em;
display:grid;
grid-template-columns: 1fr ;
`


export const PRName = styled.div``

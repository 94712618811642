import styled from "styled-components"
import { CommonParagraph, CommonSubParagraph } from "../../../common/Texts"
import { Input as InputBase } from "../../../common/Input"
import { Button as ButtonBase } from "../../../common/Button"
import { PasswordInput as PasswordInputBase } from "../../../common/PasswordInput"

export const PasswordInput = styled(PasswordInputBase)`
  width: 80%;
`

export const Input = styled(InputBase)`
  width: 80%;
  box-sizing: border-box;
  margin: 1em auto;
`
export const Submit = styled(ButtonBase)`
  width: 40%;
  min-width: 120px;
  margin: auto;
`

export const SendRequest = styled(CommonParagraph)`
  font-weight: bold;
  cursor: pointer;
`

export const SendRequestMessage = styled(CommonSubParagraph)`
  max-width: 200px;
`

export const Error = styled(CommonParagraph)`
  color: ${({ theme }) => theme.palette.error.main};
`

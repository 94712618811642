import React from "react"

import { SelectionPointRelaisView } from "./SelectionPointRelais.view"

interface Props {
  zipCode?: string
  city?: string
}

export function SelectionPointRelaisContainer({ zipCode, city }: Props) {
  // on utilise uniquement zip et city pour notre recherche
  var prop = {
    zipCode,
    city,
    countryCode: "FR",
    address: undefined,
    shippingDate: new Date(),
  }

  return <SelectionPointRelaisView {...prop} />
}

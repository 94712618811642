import React, { useState } from "react"
import { useAuth } from "@saleor/sdk"
import {
  Wrapper,
  Address,
  AddBtn,
  Text,
  Popin,
  ListAdresses,
  ButtonArea,
} from "./Addresses.style"
import { Icon } from "./../../../common/Icon"
import { CreateAddressForm } from "./../../../common/CreateAddressForm"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { v4 as uuidv4 } from "uuid"

interface Props {
  className?: string
}
export function AddressesView({ className }: Props) {
  const { user } = useAuth()
  const [displayAddAddressPopin, setDisplayAddAddressPopin] = useState(false)
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  return (
    <Wrapper className={classNames}>
      <ListAdresses className={isMobile && "mobile"}>
        {user &&
          user.addresses?.map(address => (
            <Address key={uuidv4()} address={address} />
          ))}
      </ListAdresses>
      <ButtonArea>
        <AddBtn onClick={e => setDisplayAddAddressPopin(true)}>
          <Icon icon="plus" />
          <Text>Ajouter une nouvelle adresse</Text>
        </AddBtn>
      </ButtonArea>

      {displayAddAddressPopin && (
        <Popin
          className={isMobile && "mobile"}
          onClickOutside={e => {
            setDisplayAddAddressPopin(false)
          }}
          width={isMobile ? "80%" : "50%"}
          height="auto"
          title="Nouvelle adresse"
        >
          <CreateAddressForm closeAction={() => setDisplayAddAddressPopin(false)} />
        </Popin>
      )}
    </Wrapper>
  )
}

export enum CheckoutStep {
  Base = 1,
  ProductList,
  Shipping,
  Payment,
  PaymentFinal,
}
export const CHECKOUT_STEPS: IStepInfo[] = [
  {
    index: 0,
    link: "/checkout/",
    name: "Base",
    nextActionName: "Continue to Products shipping",
    step: CheckoutStep.Base,
  },
  // {
  //   index: 1,
  //   link: "/checkout/product-list",
  //   name: "Authentification",
  //   nextActionName: "Continue to Shipping",
  //   step: CheckoutStep.ProductList,
  // },
  {
    index: 1,
    link: "/checkout/shipping",
    name: "Shipping",
    nextActionName: "Continue to Payment",
    step: CheckoutStep.Shipping,
  },
  {
    index: 2,
    link: "/checkout/payment",
    name: "Payment",
    nextActionName: "Continue to Review",
    step: CheckoutStep.Payment,
  },
  {
    index: 3,
    link: "/checkout/payment-final",
    name: "Payment terminé",
    step: CheckoutStep.PaymentFinal,
  },
]
export interface IStepInfo {
  index: number
  link: string
  name: string
  nextActionName?: string
  step: CheckoutStep
}

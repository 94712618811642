import styled from "styled-components"
import { Button } from "../../../common/Button"
import { Input as InputBase } from "../../../common/Input"
import { Button as ButtonBase } from "../../../common/Button"
import { CommonParagraph } from "../../../common/Texts"
import { PasswordInput as PasswordInputBase } from "../../../common/PasswordInput"
import { Form } from "../../../common/CreateAddressForm/CreateAddressForm.style"

export const PasswordInput = styled(PasswordInputBase)`
  width: 100%;
`
export const NewMdp = styled.input`
  width: 26%;
  height: 50px;
  margin: 20px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`
export const Wrapper = styled(Form)`
  display: flex;
  justify-content: center;
`
export const Validate = styled(Button)`
  padding: 5px 20px;
  border-radius: 10px;
  height: auto;
  width: 250px;
  background-color: ${({ theme }) => theme.palette.button.main};
  color: ${({ theme }) => theme.palette.button.contrastText};
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  line-height: ${({ theme }) => theme.typography.button.lineHeight};
  cursor: pointer;
`
export const Input = styled(InputBase)`
  width: 80%;
  box-sizing: border-box;
  margin: 1em auto;
  &.mobile {
    font-size: 1rem;
  }
`
export const Submit = styled(ButtonBase)`
  width: 40%;
  min-width: 120px;
  margin: auto;
`

export const Error = styled(CommonParagraph)`
  color: ${({ theme }) => theme.palette.error.main};
`

export const Valid = styled(CommonParagraph)`
  color: green;
`

import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Label = styled.div`
  border: 1px solid seagreen;
`

import styled from "styled-components"
import { CommonTitle } from "./../../common/Texts"
import { Button } from "./../../common/Button"

export const Wrapper = styled.div`
  padding: 20px;
  box-sizing: border-box;
  font-size: 80%;
  &.mobile {
    padding-top: 10%;
  }
`

export const Title = styled(CommonTitle)``

export const Row = styled.div`
  display: flex;
  justify-content: center;
`

export const BtnSelectInfo = styled.button`
  display: flex;
  justify-content: flex-end !important;
  white-space: nowrap;
  width: auto;
  border: none;
  padding: 1em;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.palette.common.black};
    background: white;
  }
`

export const TabContent = styled.div``

export const PlaceLogout = styled.div`
  display: flex;
  justify-content: center;
`

export const Logout = styled(Button)`
  margin-top: 3%;
  background-color: ${({ theme }) => theme.palette.error.main};
  border: solid ${({ theme }) => theme.palette.error.main};
`

export const Tab = styled.div`
  display: flex;
  height: auto;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.primary.light};
  &.mobile {
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
  }
`

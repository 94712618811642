import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useAuth } from "@saleor/sdk"
import { Spinner } from "./../Spinner"

// import { isLoggedIn } from "../services/auth"
export const PrivateRoute = ({ component: Child, location, ...rest }) => {
  const [isConnected, setConnected] = useState(false)
  const { authenticated, user, signIn } = useAuth()

  useEffect(() => {
    ; (async function () {
      if (authenticated) {
        setConnected(true)
      } else {
        if (location.pathname !== `/app/login`) {
          navigate("/app/login")
          return null
        }
      }
    })()
  }, [authenticated])

  return (
    <>
      {!isConnected && <Spinner />}
      {isConnected && <Child {...rest} user={user} />}
    </>
  )
}

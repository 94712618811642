import React, { useState } from "react"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { Wrapper, Field, Input, Icon, Info } from "./PasswordInput.style"

interface Props {
  className?: string
  // Register comes from react-hook-form
  register: Function
}

export function PasswordInputView({ className, register }: Props) {
  const [isVisible, setIsVisible] = useState(false)
  const isMobile = useDetectDevice()
  const toggleVisiblity = () => {
    setIsVisible(isVisible ? false : true)
  }
  const classNames = (className && className) + " " + (isMobile && "mobile")
  return (
    <Wrapper className={classNames}>
      <Field>
        <Input
          className={isMobile && "mobile"}
          placeholder="Mot de passe *"
          type={isVisible ? "text" : "password"}
          {...register("password", {
            required: "Vous devez saisir un mot de passe",
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&.]{8,}$/,
              message: "Le format du mot de passe est incorrect",
            },
          })}
        />
        <Icon
          icon={isVisible ? "eye-blocked" : "eye"}
          color="grey"
          size={20}
          onClick={toggleVisiblity}
        />
      </Field>
      <Info className={isMobile && "mobile"}>
      Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 majuscule, 1 minuscule, 1 chiffre et peut contenir des caractères spéciaux parmi la liste : @,$,!,%,*,?,&,.      </Info>
    </Wrapper>
  )
}

import { useCheckout, useCart } from "@saleor/sdk"
import { IItems } from "@saleor/sdk/lib/api/Cart/types"
import { ICheckoutModelLine } from "@saleor/sdk/lib/helpers"
import React from "react"
import { useForm } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import { fixPrice } from "../../../../helper/fixedPrice"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { Icon } from "../../../common/Icon"
import {
  Add,
  Column,
  RowPrice,
  List,
  Amount,
  TotalAmount,
  TotalAmountText,
  Promo,
  DiscountInput,
  Row,
  AmountText,
  Wrapper,
  Title,
  EditCart,
  PriceWrapper,
  Form,
  WrapperTitle,
  TH,
  ProductTH,
  VariantTH,
  QtyTH,
  PriceTH,
  RowTotalPrice,
  TextDiscount,
} from "./ProductList.style"
import { ProductRow } from "./ProductRow"
import InfiniteScroll from "react-infinite-scroll-component"

interface Props {
  items: IItems
  totalPriceInitial: any
  metaProducts: any
  onLoadMore: () => void
}

interface IFormInput {
  code: string
}

export function ProductListView({
  items,
  totalPriceInitial,
  metaProducts = [],
  onLoadMore,
}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>()
  const { totalPrice, subtotalPrice, shippingPrice, discount } = useCart()
  const { promoCodeDiscount, addPromoCode, removePromoCode } = useCheckout()

  const onSubmit = (data: IFormInput) => {
    // TODO: display error if exists
    addPromoCode(data.code)
  }
  const isMobile = useDetectDevice()
  const classMobile = isMobile ? "mobile" : ""
  var listMaxShipping: any = []
  var listMinShipping: any = []
  var maxShippingMedian: Number = 0
  var minShippingMedian: Number = 0
  let block = false
  metaProducts?.map(item => {
    const max = item?.find(x => x.key == "max_shipping")?.value || 14
    const min = item?.find(x => x.key == "min_shipping")?.value || 7
    listMaxShipping.push(max)
    listMinShipping.push(min)
  })
  if (items.length > 1) {
    block = true
  }
  if (listMaxShipping?.length > 0 && listMinShipping?.length > 0) {
    listMaxShipping.forEach((element: Number) => {
      if (maxShippingMedian < element) {
        maxShippingMedian = element
      }
    })
    listMinShipping.forEach((element: Number) => {
      if (minShippingMedian < element) {
        minShippingMedian = element
      }
    })
  }
  if (!items?.length) return <></>

  return (
    <Wrapper className={classMobile}>
      <WrapperTitle>
        <Title>Récapitulatif de votre commande</Title>
      </WrapperTitle>

      <InfiniteScroll
        dataLength={items.length}
        next={onLoadMore}
        hasMore={true}
      >
        <List className={classMobile}>
          <TH className={classMobile}>
            <ProductTH>Produit</ProductTH>
            <VariantTH>Dimensions & couleur</VariantTH>
            <QtyTH>Qté</QtyTH>
            <PriceTH>Prix</PriceTH>
          </TH>
          {items &&
            items.map((item: ICheckoutModelLine) => (
              <ProductRow key={uuidv4()} data={item} />
            ))}
        </List>
      </InfiniteScroll>
      <PriceWrapper className={classMobile}>
        <Column className={classMobile}>
          <TextDiscount>Vous avez un code de réduction ?</TextDiscount>
          <Column>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <DiscountInput
                placeholder="Code de réduction"
                {...register("code", {
                  required: false,
                })}
              />
              {errors.code && <p>Champs incorrect</p>}
              <Add type="submit">+</Add>
            </Form>
            <EditCart
             className={classMobile}
              onClick={() => {
                // on force le rechargement de la page avec location pour "eviter" le bug panier
                window.location.href = "/"
              }}
            >
              Je souhaite modifier le panier
            </EditCart>
            </Column>
        </Column>
        <Column>
          <RowPrice className={classMobile}>
            <AmountText className={classMobile}>Total :</AmountText>
            <Amount className={classMobile}>
              {fixPrice(totalPriceInitial?.gross.amount)}{" "}
              {totalPriceInitial?.gross.currency}
            </Amount>
          </RowPrice>
          <RowPrice className={classMobile}>
            <AmountText className={classMobile}>Total Remisé :</AmountText>
            <Amount className={classMobile}>
              {fixPrice(subtotalPrice?.gross.amount)}{" "}
              {subtotalPrice?.gross.currency}
            </Amount>
          </RowPrice>
          {discount?.amount > 0 && (
            <RowPrice className={classMobile}>
              <AmountText className={classMobile}>Code promo :</AmountText>
              <Row>
                <Promo className={classMobile}>
                  -{discount?.amount} {discount?.currency} (
                  {promoCodeDiscount?.voucherCode})
                </Promo>
                <Icon
                  icon="cross"
                  onClick={() => {
                    removePromoCode(promoCodeDiscount.voucherCode)
                  }}
                />
              </Row>
            </RowPrice>
          )}

          <RowPrice className={classMobile}>
            <AmountText className={classMobile}>Livraison :</AmountText>
            <Amount className={classMobile}>
              {fixPrice(shippingPrice?.amount)} {shippingPrice?.currency}
            </Amount>
          </RowPrice>
        </Column>
      </PriceWrapper>
      <RowTotalPrice className={classMobile}>
        <TotalAmountText className={classMobile}>A payer :</TotalAmountText>
        <TotalAmount className={classMobile}>
          {fixPrice(totalPrice?.gross.amount)} {totalPrice?.gross.currency}
        </TotalAmount>
      </RowTotalPrice>
    </Wrapper>
  )
}

import { useCheckout, useAuth } from "@saleor/sdk"
import React from "react"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"
import { Spinner } from "../../../common/Spinner"

import { CustomerIdentityView } from "./CustomerIdentity.view"

interface Props {
  className?: string
}

export function CustomerIdentityContainer({ className }: Props) {
  const { loaded: loadedCart } = useCartWithTotal()
  const { loaded: loadedCheckout } = useCheckout()
  const { loaded: loadedAuth } = useAuth()

  if (!loadedCart || !loadedCheckout || !loadedAuth) return <Spinner />

  return (
    <CustomerIdentityView
      className={className}
      isCheckoutReady={loadedCart && loadedCheckout && loadedAuth}
    />
  )
}




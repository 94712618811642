import { useEffect } from "react"
import L from "leaflet"
import marker1 from "leaflet/dist/images/marker-icon-2x.png"
import marker2 from "leaflet/dist/images/marker-icon.png"
import marker3 from "leaflet/dist/images/marker-shadow.png"

export function _useConfigureLeaflet() {
  useEffect(() => {
    // To get around an issue with the default icon not being set up right between using React
    // and importing the leaflet library, we need to reset the image imports
    // See https://github.com/PaulLeCam/react-leaflet-universal/issues/453#issuecomment-410450387

    delete L.Icon.Default.prototype._getIconUrl

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: marker1,
      iconUrl: marker2,
      shadowUrl: marker3,
    })
  }, [])
}

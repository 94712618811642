import styled from "styled-components"

import { CommonParagraph } from "./../../../../common/Texts"
import { Popin as PopinBase } from "./../../../../common/Popin"
import { Content } from "../../../../common/Popin/Popin.style"

export const Wrapper = styled.div`
  box-sizing: border-box;
  min-width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.palette.primary.light};
  &.mobile {
    min-width: 90%;
    padding: 5% 0;
    width: 90%;
  }
`

export const RecapInfo = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5%;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  border-left: 1px solid ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  border: none;
  p {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
  }
`

export const Row = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
`
export const RecapTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80%;
  padding-left: 3%;
`

export const Button = styled.button`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: none;
  &:hover {
    background-color: ${({ theme }) => theme.palette.button.homepage};
  }
`

export const Title = styled(CommonParagraph)`
  margin: 0;
  padding: 0 15px;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: bold;
`

export const Text = styled(CommonParagraph)`
  line-height: 100%;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Menu = styled(PopinBase)`
  left: 0;
  ${Content} {
    border-radius: 1%;
    align-items: stretch;
    height: auto;
  }
`

export const Entry = styled.button`
  height: 50px;
  margin: 5px;
  font-size: medium;
  border: none;
  border-radius: 3%;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  background-color: #d9d9d9;
`

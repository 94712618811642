import React from "react"
import { CheckoutStep } from "../../../../core/config"
import { getNextStepFromEnum } from "../../../../helper/getNextStep"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { Wrapper, Row, StyledRegisterOrLogin } from "./CustomerIdentity.style"

interface Props {
  className?: string
  isCheckoutReady: boolean
}

export function CustomerIdentityView({ className, isCheckoutReady }: Props) {
  const isMobile = useDetectDevice()
  const nextStape = getNextStepFromEnum(CheckoutStep.Base)
  return (
    <Wrapper className={className}>
      <StyledRegisterOrLogin
        redirectUrl={`/app${nextStape?.link}`}
        className={isMobile && "mobile"}
        isCheckout={true}
        isCheckoutReady={isCheckoutReady}
      />
    </Wrapper>
  )
}

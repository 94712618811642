import styled from "styled-components"
import { Input as InputBase } from "../../Input"
import { Button as ButtonBase } from "../../Button"
import { CommonParagraph } from "../../Texts"
import { Link as LinkBase } from "../../Link"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
`
export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  &.mobile {
    width: 80%;
    justify-content: space-around;
  }
`

export const WrapperButton1 = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperButton2 = styled.div``

export const ErrorFormSent = styled(CommonParagraph)`
  color: ${({ theme }) => theme.palette.error.main};
`

export const Error = styled(ErrorFormSent)`
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
`

export const Input = styled(InputBase)`
  width: 80%;
  box-sizing: border-box;
  &.mobile {
    font-size: 1rem;
  }
`

export const Submit = styled(ButtonBase)`
  max-height: 3em;
`

export const Link = styled(LinkBase)`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Wrappers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  &.mobile {
    width: 90%;
  }
`

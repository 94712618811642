import { gql } from "@apollo/client"
import { useStaticQuery, graphql } from "gatsby"

export const RESET_PASSWORD = gql`
  mutation ResetPasswordRequest($email: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
      accountErrors {
        field
        message
      }
    }
  }
`
import { gql } from "@apollo/client"

export const GET_COUNTRY_AREAS = gql`
  query getCountryAreas(
    $countryCode: CountryCode!
    ){
    addressValidationRules(
      countryCode: $countryCode
    ){
      countryCode
      countryName
      addressFormat
      addressLatinFormat
      allowedFields
      requiredFields
      upperFields
      countryAreaType
      cityAreaChoices {
        raw
        verbose
      }
      cityType
      cityChoices {
        raw
        verbose
      }
      cityAreaType
      countryAreaChoices {
        raw
        verbose
      }
      postalCodeType
      postalCodeMatchers
      postalCodeExamples
      postalCodePrefix
    }
  }
`
import { debug } from "console"
import React from "react"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { changeActivePointLivraison } from "../../../../../../../../entities/PointLivraison/PointLivraison.reducer"
import {
  PointRelais,
} from "../../../../../../../../lib/map"
import { MarkerPointRelais } from "./MarkerPointRelais"

interface Props {
  lstMarker: Array<PointRelais>
}

export const ZoneLivraisonMarker = React.memo(({ lstMarker = [] }: Props) => {
  const dispatch = useDispatch()

  const setReduxState = (
    IdPointRelais: string,
    pointLivraison: PointRelais
  ) => {
    dispatch(changeActivePointLivraison(IdPointRelais, pointLivraison))
  }

  return (
    <>
      {lstMarker.map((item: PointRelais) => {
        const pt = { ...item } as PointRelais
        return (
          <MarkerPointRelais
            itemkey={uuidv4()}
            pointRelais={pt}
            setReduxState={setReduxState}
          />
        )
      })}
    </>
  )
})

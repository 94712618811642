import styled from "styled-components"

export const SelectCountry = styled.select`
  width: 80%;
  box-sizing: border-box;
  margin: 2% 1%;
  &.mobile {
    font-size: 0.9rem;
    color: black;
    padding: 9px;
  }
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: 20px;
  &:focus {
    outline: none;
  }
`

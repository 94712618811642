import styled from "styled-components"
import { LoginForm as LoginFormBase } from "./LoginForm"
import { CreateAccountForm as CreateAccountFormBase } from "./CreateAccountForm"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
`
export const SelectChoice = styled.div``

export const BtnSelectInfo = styled.button`
  background-color: white;
  border: none;
  padding: 20px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  &.active {
    border-bottom: 5px solid ${({ theme }) => theme.palette.primary.main};
  }
`

export const LoginForm = styled(LoginFormBase)``

export const CreateAccountForm = styled(CreateAccountFormBase)``

export const SelectCheckinCheckout = styled.div`
  display: flex;
  justify-content: center;
`

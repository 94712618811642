import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { PointRelais } from "../../../../../../../../lib/map"
import { StyledMarker, StyledPopup } from "../Map.style"
import icon from "./../../../../../../../../images/delivery-truck 1.png"
import shadow from "leaflet/dist/images/marker-shadow.png"

interface Props {
  itemkey: string
  pointRelais: PointRelais
  setReduxState: Function
}

export const MarkerPointRelais = ({
  pointRelais,
  itemkey,
  setReduxState,
}: Props) => {
  const markerRef = useRef(null)
  const [selectedState, setSelectedState] = React.useState(false)
  const { selected: selectedRedux } = useSelector(
    state => state.activePointLivraisonReducer
  )

  const position = {
    lat: pointRelais.coordGeolocalisationLatitude,
    lng: pointRelais.coordGeolocalisationLongitude,
  }

  const setActive = (value: boolean) => {
    setSelectedState(value)
    if (value) {
      setReduxState(pointRelais._IdPointRelais, pointRelais)
    }
  }

  useEffect(() => {
    if (selectedRedux == pointRelais._IdPointRelais && !selectedState) {
      setSelectedState(true)
      markerRef.current?.openPopup()
    }

    if (selectedRedux != pointRelais._IdPointRelais && selectedState) {
      setSelectedState(false)
    }
  }, [selectedRedux])

  return (
    <StyledMarker
      key={itemkey}
      position={position}
      icon={L.icon({
        iconUrl: icon,
        shadowUrl: shadow,
        shadowAnchor: [15, 25],
        iconSize: [25, 25],
        iconAnchor: [10, 10],
        popupAnchor: [-3, -10],
      })}
      ref={markerRef}
      eventHandlers={{
        popupopen: () => {
          setActive(true)
        },
        popupclose: () => {
          setActive(false)
        },
      }}
    >
      <StyledPopup>
        <span>{pointRelais.Name}</span>
      </StyledPopup>
    </StyledMarker>
  )
}

import { usePasswordChange } from "@saleor/sdk"
import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

import {
  Input,
  Submit,
  Error as ErrorMessage,
  PasswordInput,
  Wrapper,
} from "./ChangePassword.style"

interface IFormInput {
  oldPassword: string
  password: string
  password_repeat: string
}

export function ChangePasswordView() {
  const [error, setError] = useState("")
  const [setPasswordChange] = usePasswordChange()
  const isMobile = useDetectDevice()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>()

  const password = useRef({})
  password.current = watch("password", "")

  const onSubmit = data => {
    setError("")
    setPasswordChange({
      newPassword: data.password,
      oldPassword: data.oldPassword,
    })
      .then(res => {
        if (!res && res.error) {
          setError(res.error)
        } else {
          alert("Le mot de passe a bien été changé")
        }
      })
      .catch(e => {
        setError("L'ancien mot de passe est invalide")
      })
  }
  // {user?.email}
  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} className={isMobile && "mobile"}>
      <Input
        className={isMobile && "mobile"}
        placeholder="Ancien mot de passe"
        type="password"
        {...register("oldPassword", {
          required: "Vous devez saisir un mot de passe",
        })}
      />
      <PasswordInput register={register} />
      <Input
        className={isMobile && "mobile"}
        placeholder="Confirmez le mot de passe *"
        type="password"
        {...register("password_repeat", {
          validate: value =>
            value === password.current || "Les mots de passe sont différents",
        })}
      />
      {errors.password_repeat && (
        <ErrorMessage>{errors.password_repeat.message}</ErrorMessage>
      )}
      {errors.password && (
        <ErrorMessage>{errors.password.message}</ErrorMessage>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Submit type="submit" className={isMobile && "mobile"}>
      VALIDER
      </Submit>
    </Wrapper>
  )
}

import React, { useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import {
  useAuth,
  useAccountUpdate,
  CountryCode,
  useCreateUserAddress,
  useDefaultUserAddress,
  AddressTypeEnum,
} from "@saleor/sdk"
import { useMutation } from "react-apollo"
import { CONFIRM_ACCOUNT } from "./StepTwo.queries"
import { CommonWrapper } from "../../../CommonWrapper"
import {
  Wrapper,
  Input,
  SelectCountry,
  Submit,
  Error,
  Wrappers,
  ErrorFormSent,
  FormSent,
  LabelFormResult,
  ListErrors,
  ContainerLabelAccount,
} from "./StepTwo.style"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"

interface Props {
  email: string
  token: string
  // redirectUrl?: string
  className?: string
}

interface IFormInput {
  email: string
  firstName: string
  lastName: string
  age: number
  country: CountryCode
  city: string
  streetAddress1: string
  streetAddress2?: string
  postalCode: string
  isActive?: boolean
  note: string
  companyName: string
  cityArea?: string
  countryArea?: string
  phone: string
  password: string
  password_repeat: string
}

// export function StepTwoView({ className, redirectUrl = "/" }: Props) {
  export function StepTwoView({ email, token, className }: Props) {
  const { user, signOut, signIn } = useAuth()
  const [setAccountUpdate] = useAccountUpdate()
  const [setDefault] = useDefaultUserAddress()
  const [setAdressesUpdate] = useCreateUserAddress()
  const [confirmAccount] = useMutation(CONFIRM_ACCOUNT)
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>()
  const [customSuccess, setCustomSuccess] = useState(false)
  const [customError, setCustomError] = useState(false)
  const isMobile = useDetectDevice()

  const initConfirmation = async ()=>{
    let result = await confirmAccount({
      variables: {
        email: email,
        token: token,
      },
    })
  }

  useEffect(() => {
    if (user  && user.firstName) {
      navigate('/')
    }
  }, [user])

  const onSubmit = async (data: IFormInput) => {
    await initConfirmation()
    // If a user is already logged in, I log him out, then I create the new account
    await signOut()
    let resSignIn = await signIn(email, data.password)
    if(resSignIn?.dataError?.error[0]){
      if(resSignIn.dataError.error[0].code=="INVALID_CREDENTIALS"){
        setCustomError("Mot de passe incorrect.")
      }else{
        setCustomError("Une erreur est survenue.")
      }
    }else{
      let address = await setAdressesUpdate({
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          //companyName: data.companyName,
          streetAddress1: data.streetAddress1,
          streetAddress2: data.streetAddress2,
          city: data.city,
          cityArea: data.cityArea,
          postalCode: data.postalCode,
          country: data.country,
          countryArea: data.countryArea,
          phone: data.phone,
        },
      })
      if (!address?.data?.user?.addresses[0].id) {
        console.error('User Adresses not found')
      }
  
      await setAccountUpdate({
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      })
  
      await setDefault({
        id: address.data?.user.addresses[0].id,
        type: AddressTypeEnum.SHIPPING,
      })
  
      await setDefault({
        id: address.data?.user.addresses[0].id,
        type: AddressTypeEnum.BILLING,
      })
    }
  }
  
  let classMobile = isMobile?"mobile":""
  return (
    <Wrapper className={className}>
      <CommonWrapper>
        <ContainerLabelAccount>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Wrappers className={classMobile}>
              Merci {email}, pour finaliser la création de votre compte, veuillez saisir les informations ci-dessous:
              <Input
                className={classMobile}
                placeholder="Mot de passe *"
                type="password"
                {...register("password", {
                  required: "Vous devez saisir un mot de passe",
                  pattern: {
                    value: /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/i,
                    message: "Doit contenir au moins 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial",
                  },
                })}
              />
              <Input
                className={classMobile}
                placeholder="Nom *"
                {...register("lastName", {
                  required: true,
                  maxLength: 20,
                  pattern: /^[a-zà-ÿœ][a-zà-ÿœ\-' ]*$/gim,
                })}
              />
              <Input
                className={classMobile}
                placeholder="Prénom *"
                {...register("firstName", {
                  required: true,
                  maxLength: 20,
                  pattern: /^[a-zà-ÿœ][a-zà-ÿœ\-' ]*$/gim,
                })}
              />
              <Input
                className={classMobile}
                placeholder="Numéro et rue *"
                {...register("streetAddress1", { required: true })}
              />
              <Input
                className={classMobile}
                placeholder="Informations complémentaires"
                {...register("note", { required: false })}
              />
              <Input
                className={classMobile}
                placeholder="Code Postal *"
                {...register("postalCode", {
                  required: true,
                  pattern: /^\d{5}$/i,
                })}
              />
              <Input
                className={classMobile}
                placeholder="Ville *"
                {...register("city", { required: true })}
              />
              <SelectCountry className={classMobile} {...register("country")} defaultValue="FR">
              <option value="AF">Afghanistan </option>
              <option value="ZA">Afrique du Sud </option>
              <option value="AL">Albanie </option>
              <option value="DZ">Algérie </option>
              <option value="DE">Allemagne </option>
              <option value="AD">Andorre </option>
              <option value="AO">Angola </option>
              <option value="AI">Anguilla </option>
              <option value="AQ">Antarctique </option>
              <option value="AG">Antigua-et-Barbuda </option>
              <option value="SA">Arabie Saoudite </option>
              <option value="AR">Argentine </option>
              <option value="AM">Arménie </option>
              <option value="AW">Aruba </option>
              <option value="AU">Australie </option>
              <option value="AT">Autriche </option>
              <option value="AZ">Azerbaïdjan </option>
              <option value="BS">Bahamas </option>
              <option value="BH">Bahreïn </option>
              <option value="BD">Bangladesh </option>
              <option value="BB">Barbade </option>
              <option value="BE">Belgique </option>
              <option value="BZ">Belize </option>
              <option value="BJ">Bénin </option>
              <option value="BM">Bermudes </option>
              <option value="BT">Bhoutan </option>
              <option value="BY">Biélorussie </option>
              <option value="BO">Bolivie </option>
              <option value="BA">Bosnie-Herzégovine </option>
              <option value="BW">Botswana </option>
              <option value="BR">Brésil </option>
              <option value="VG">British Virgin Islands </option>
              <option value="BN">Brunei Darussalam </option>
              <option value="BG">Bulgarie </option>
              <option value="BF">Burkina Faso </option>
              <option value="BI">Burundi </option>
              <option value="KH">Cambodge </option>
              <option value="CM">Cameroun </option>
              <option value="CA">Canada </option>
              <option value="CV">Cap-Vert </option>
              <option value="CL">Chili </option>
              <option value="CN">Chine </option>
              <option value="CY">Chypre </option>
              <option value="CO">Colombie </option>
              <option value="KM">Comores </option>
              <option value="KP">Corée du Nord </option>
              <option value="KR">Corée du Sud </option>
              <option value="CR">Costa Rica </option>
              <option value="CI">Côte d’Ivoire </option>
              <option value="HR">Croatie </option>
              <option value="CU">Cuba </option>
              <option value="DK">Danemark </option>
              <option value="DJ">Djibouti </option>
              <option value="DM">Dominique </option>
              <option value="EG">Égypte </option>
              <option value="AE">Émirats Arabes Unis </option>
              <option value="EC">Équateur </option>
              <option value="ER">Érythrée </option>
              <option value="ES">Espagne </option>
              <option value="EE">Estonie </option>
              <option value="SZ">Eswatini </option>
              <option value="US">États-Unis </option>
              <option value="ET">Éthiopie </option>
              <option value="FJ">Fidji </option>
              <option value="FI">Finlande </option>
              <option value="FR">France </option>
              <option value="GA">Gabon </option>
              <option value="GM">Gambie </option>
              <option value="GE">Géorgie </option>
              <option value="GS">Géorgie du Sud et les îles Sandwich du Sud </option>
              <option value="GH">Ghana </option>
              <option value="GI">Gibraltar </option>
              <option value="GR">Grèce </option>
              <option value="GD">Grenade </option>
              <option value="GL">Groenland </option>
              <option value="GP">Guadeloupe </option>
              <option value="GU">Guam </option>
              <option value="GT">Guatemala </option>
              <option value="GG">Guernesey </option>
              <option value="GN">Guinée </option>
              <option value="GQ">Guinée équatoriale </option>
              <option value="GW">Guinée-Bissau </option>
              <option value="GY">Guyane </option>
              <option value="GF">Guyane française </option>
              <option value="HT">Haïti </option>
              <option value="HN">Honduras </option>
              <option value="HK">Hong Kong </option>
              <option value="HU">Hongrie </option>
              <option value="BV">Île Bouvet </option>
              <option value="CX">Île Christmas </option>
              <option value="IM">Ile de Man </option>
              <option value="NF">Île Norfolk </option>
              <option value="AX">Îles Åland </option>
              <option value="KY">Iles Cayman </option>
              <option value="CC">Îles Cocos </option>
              <option value="CK">Îles Cook </option>
              <option value="FK">Îles Falkland </option>
              <option value="FO">Îles Féroé </option>
              <option value="HM">Îles Heard-et-MacDonald </option>
              <option value="MP">Îles Mariannes du Nord </option>
              <option value="MH">Îles Marshall </option>
              <option value="UM">Îles mineures éloignées des États-Unis </option>
              <option value="SB">Îles Salomon </option>
              <option value="TC">Îles Turques-et-Caïques </option>
              <option value="VI">Îles Vierges américaines </option>
              <option value="IN">Inde </option>
              <option value="ID">Indonésie </option>
              <option value="IQ">Irak </option>
              <option value="IR">Iran </option>
              <option value="IE">Irlande </option>
              <option value="IS">Islande </option>
              <option value="IL">Israël </option>
              <option value="IT">Italie </option>
              <option value="JM">Jamaïque </option>
              <option value="JP">Japon </option>
              <option value="JE">Jersey </option>
              <option value="JO">Jordanie </option>
              <option value="KZ">Kazakhstan </option>
              <option value="KE">Kenya </option>
              <option value="KG">Kirghizistan </option>
              <option value="KI">Kiribati </option>
              <option value="KW">Koweït </option>
              <option value="LA">Laos </option>
              <option value="LS">Lesotho </option>
              <option value="LV">Lettonie </option>
              <option value="LB">Liban </option>
              <option value="LR">Libéria </option>
              <option value="LY">Libye </option>
              <option value="LI">Liechtenstein </option>
              <option value="LT">Lituanie </option>
              <option value="LU">Luxembourg </option>
              <option value="MO">Macao </option>
              <option value="MK">Macédoine </option>
              <option value="MG">Madagascar </option>
              <option value="MY">Malaisie </option>
              <option value="MW">Malawi </option>
              <option value="MV">Maldives </option>
              <option value="ML">Mali </option>
              <option value="MT">Malte </option>
              <option value="MA">Maroc </option>
              <option value="MQ">Martinique </option>
              <option value="MU">Maurice </option>
              <option value="MR">Mauritanie </option>
              <option value="YT">Mayotte </option>
              <option value="MX">Mexique </option>
              <option value="FM">Micronésie </option>
              <option value="MD">Moldavie </option>
              <option value="MC">Monaco </option>
              <option value="MN">Mongolie </option>
              <option value="ME">Monténégro </option>
              <option value="MS">Montserrat </option>
              <option value="MZ">Mozambique </option>
              <option value="MM">Myanmar </option>
              <option value="NA">Namibie </option>
              <option value="NR">Nauru </option>
              <option value="NP">Népal </option>
              <option value="NI">Nicaragua </option>
              <option value="NE">Niger </option>
              <option value="NG">Nigeria </option>
              <option value="NU">Niue </option>
              <option value="NO">Norvège </option>
              <option value="NC">Nouvelle-Calédonie </option>
              <option value="NZ">Nouvelle-Zélande </option>
              <option value="OM">Oman </option>
              <option value="UG">Ouganda </option>
              <option value="UZ">Ouzbékistan </option>
              <option value="PK">Pakistan </option>
              <option value="PW">Palau </option>
              <option value="PS">Palestine </option>
              <option value="PA">Panama </option>
              <option value="PG">Papouasie-Nouvelle-Guinée </option>
              <option value="PY">Paraguay </option>
              <option value="NL">Pays-Bas </option>
              <option value="PE">Pérou </option>
              <option value="PH">Philippines </option>
              <option value="PN">Pitcairn </option>
              <option value="PL">Pologne </option>
              <option value="PF">Polynésie française </option>
              <option value="PT">Portugal </option>
              <option value="PR">Puerto Rico </option>
              <option value="QA">Qatar </option>
              <option value="CF">République centrafricaine </option>
              <option value="CD">République démocratique du Congo </option>
              <option value="DO">République dominicaine </option>
              <option value="CG">République du Congo </option>
              <option value="CZ">République tchèque </option>
              <option value="RE">Réunion </option>
              <option value="RO">Roumanie </option>
              <option value="GB">Royaume-Uni </option>
              <option value="RU">Russie </option>
              <option value="RW">Rwanda </option>
              <option value="EH">Sahara occidental </option>
              <option value="BL">Saint-Barthélemy </option>
              <option value="KN">Saint-Kitts-et-Nevis </option>
              <option value="SM">Saint-Marin </option>
              <option value="MF">Saint-Martin (partie française) </option>
              <option value="SX">Saint-Martin (partie néerlandaise) </option>
              <option value="PM">Saint-Pierre-et-Miquelon </option>
              <option value="VA">Saint-Siège (Vatican) </option>
              <option value="VC">Saint-Vincent-et-les Grenadines </option>
              <option value="SH">Sainte-Hélène </option>
              <option value="LC">Sainte-Lucie </option>
              <option value="SV">Salvador </option>
              <option value="WS">Samoa </option>
              <option value="AS">Samoa américaines </option>
              <option value="ST">Sao Tomé-et-Principe </option>
              <option value="SN">Sénégal </option>
              <option value="RS">Serbie </option>
              <option value="SC">Seychelles </option>
              <option value="SL">Sierra Leone </option>
              <option value="SG">Singapour </option>
              <option value="SK">Slovaquie </option>
              <option value="SI">Slovénie </option>
              <option value="SO">Somalie </option>
              <option value="SD">Soudan </option>
              <option value="LK">Sri Lanka </option>
              <option value="SS">Sud-Soudan </option>
              <option value="SE">Suède </option>
              <option value="CH">Suisse </option>
              <option value="SR">Suriname </option>
              <option value="SJ">Svalbard et Jan Mayen </option>
              <option value="SY">Syrie </option>
              <option value="TJ">Tadjikistan </option>
              <option value="TW">Taiwan </option>
              <option value="TZ">Tanzanie </option>
              <option value="TD">Tchad </option>
              <option value="TF">Terres australes et antarctiques françaises </option>
              <option value="IO">Territoire britannique de l’Océan Indien </option>
              <option value="TH">Thaïlande </option>
              <option value="TL">Timor-Leste </option>
              <option value="TG">Togo </option>
              <option value="TK">Tokelau </option>
              <option value="TO">Tonga </option>
              <option value="TT">Trinité-et-Tobago </option>
              <option value="TN">Tunisie </option>
              <option value="TM">Turkménistan </option>
              <option value="TR">Turquie </option>
              <option value="TV">Tuvalu </option>
              <option value="UA">Ukraine </option>
              <option value="UY">Uruguay </option>
              <option value="VU">Vanuatu </option>
              <option value="VE">Venezuela </option>
              <option value="VN">Viêt Nam </option>
              <option value="WF">Wallis-et-Futuna </option>
              <option value="YE">Yémen </option>
              <option value="ZM">Zambie </option>
              <option value="ZW">Zimbabwe </option>
              </SelectCountry>
              <Input
                className={classMobile}
                placeholder="Téléphone *"
                {...register("phone", {
                  required: true,
                  pattern: /^\d{10}$/i,
                })}
              />
              <Submit type="submit">SUIVANT</Submit>
            </Wrappers>
          </form>
          <LabelFormResult>
              <ListErrors>
                {errors.password && <Error>{errors.password.message}</Error>}
                {errors.lastName && <Error>Nom incorrect</Error>}
                {errors.firstName && <Error>Prénom incorrect</Error>}
                {errors.streetAddress1 && <Error>Adresse incorrecte</Error>}
                {errors.postalCode && <Error>Code Postal incorrect</Error>}
                {errors.note && <p>{errors.note.message}</p>}
                {errors.city && <p>{errors.city.message}</p>}
                {errors.phone && <Error>Téléphone incorrect</Error>}
                {customError && <Error>{customError}</Error>}
              </ListErrors>
              {customSuccess && <FormSent>{customSuccess}</FormSent>}
            </LabelFormResult>
          </ContainerLabelAccount>
      </CommonWrapper>
    </Wrapper>
  )
}

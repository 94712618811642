import React from "react"

import { CheckoutPaymentView } from "./CheckoutPayment.view"

interface Props {}

export function CheckoutPaymentContainer({}: Props) {
  let handleStepPaymentError = () => {}
  let handleStepPaymentSuccess = () => {}

  const data = { handleStepPaymentSuccess, handleStepPaymentError }
  return <CheckoutPaymentView {...data} />
}

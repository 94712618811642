import styled from "styled-components"
import { Icon } from "../../../common/Icon"
import { ImageWithLinkOrNot } from "../../../common/ImageWithLinkOrNot"
import { CommonParagraph, CommonSubtitle } from "../../../common/Texts"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Label = styled(CommonParagraph)`
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin: auto;
`

export const ListPaymentGateway = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column-reverse;
  margin-bottom: 10px;
  &.mobile {
    align-items: center;
    justify-content: center;
  }
`
export const ItemWrapper = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 356px;
  padding: 10px 0;
  background: #2b3944 0% 0% no-repeat padding-box;
  border-radius: 50px;
  &.mobile{
    width: 330px;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[10]};
  }

  &.selected {
    box-shadow: ${({ theme }) => theme.shadows[10]};
  }
`

export const InfoWrapper = styled.div`
  z-index: 150;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease 0s;
  background-color: rgba(44, 46, 44, 0.8);
`
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Logo = styled(ImageWithLinkOrNot)`
  width: 35%;
`

export const StyledIcon = styled(Icon)`
`

export const Subtitle = styled(CommonSubtitle)`
display: flex;
justify-content: center;
gap: 15px;
font-size: 16px;
text-align: center;
`

export const Row = styled(CommonParagraph)`
  margin: 0;
  color: #2b3944;
  font-size: 14px;
`

export const SousWrapper = styled.div`
  background-color: white;
  width: 50%;
  padding: 2%;
  &.mobile{
    width: 95%;
  }
`

export const TextWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;
  width: 75%;
`

import styled, { keyframes } from "styled-components"
import { Button } from "../../../common/Button"
import { CommonSubtitle, CommonTitle } from "../../../common/Texts"
import { Icon as Iconbase } from "../../../common/Icon"

import { Popin as PopinBase } from "../../../common/Popin"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 5px 30px 5px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Label = styled.div``
export const SpaceOnText = styled.div`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.typography.subtitle.fontSize};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const NextStepButton = styled(Button)`
  margin-left: 50%;
  margin-top: 30px;
  &.mobile {
    margin-left: 0px;
  }
`

export const ShowMapButton = styled(Button)`
  font-size: 14px;
`

export const StyledForm = styled.form`
  margin: 10px;
`

export const ZipCodeWrapper = styled.form`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`

export const ZipcodeInput = styled.input`
  width: 150px;
  height: 30px;
  font: normal normal normal 14px/17px Poppins;
`

export const CityInput = styled.input`
  margin: 10px;
  margin-top: 20px;
  width: 45%;
  height: 40px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-content: center;
  height: auto;
  box-sizing: border-box;
  max-width: 100%;
`

export const Test = styled.div``

export const Test20 = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
`
export const CloseButtn = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 29px;
  border: none;
  background: none;
  padding-top: 4px;
`
export const Icon = styled(Iconbase)``

export const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-bottom: 20px;
`

export const RadioButton = styled(Button)`
  & {
    ${({ checked }) =>
      checked
        ? "background-color: none;"
        : "background-color: transparent; color: #2B3944;"}
  }
  &:hover {
    opacity: 0.7;
    color: #C4AB88;
  }
`

export const MapORFormDomicileArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`

export const StyledCheckbox = styled.label`
  /* display: inline-flex;
  margin-right: 1em; */
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const StyledFormDomicile = styled.div`
  display: flex;
  justify-content: center;
  //overflow: auto;
  justify-content: flex-start;
`

export const StyledFormAfficherMap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
`

export const Title = styled(CommonTitle)`
  justify-self: center;
  margin-top: 20px;
  font: normal normal 600 24px/22px Cormorant Garamond;
  &.mobile {
    margin-top: 6%;
  }
`

export const Subtitle = styled(CommonSubtitle)`
  margin: 30px;
`

export const Pannel = styled.div`
  min-width: 40%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &.mobile {
    padding-left: 0;
    max-width: 100%;
  }
`

const openingCart = keyframes`
  from {right: -100%;  background-color: rgba(44, 46, 44, 0);}
  to {right: 0;    background-color: rgba(44, 46, 44, 0.8);}
`
const closingCart = keyframes`
  from {right: 0;    background-color: rgba(44, 46, 44, 0.8);}
  to {right: -100%;  background-color: rgba(44, 46, 44, 0);}
`
export const Popin = styled(PopinBase)`
  justify-content: flex-end !important;
  right: -100%;
  background-color: rgba(44, 46, 44, 0);
  &.open {
    animation: ${openingCart} 0.5s cubic-bezier(1, 0, 0, 1);
    right: 0;
    background-color: rgba(44, 46, 44, 0.8);
  }
  &.close {
    animation: ${closingCart} 0.5s cubic-bezier(1, 0, 0, 1);
    right: -100%;
    background-color: rgba(44, 46, 44, 0);
  }
`

export const ValidateButton = styled(Button)`
  margin: 10px 0;
  align-self: center;
  justify-content: center;
  width: 150px;
  z-index: 1;
`

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeActivePointLivraison } from "../../../../../../entities/PointLivraison/PointLivraison.reducer"
import { PointRelais } from "../../../../../../lib/map"
import { ItemPointRelaisView } from "./ItemPointRelais/ItemPointRelais.view"
import { Wrapper, Wrapper2, PRName, PRName1 } from "./PanelPointRelais.style"
import InfiniteScroll from "react-infinite-scroll-component"
import { v4 as uuidv4 } from "uuid"
import { store } from "../../../../../../store/configure"
import { isDomAvailable } from "../../../../../../lib"
import { useDetectDevice } from "../../../../../../lib/useDetectDevice"

interface Props {
  className?: string
  PointsRelais: Array<PointRelais>
  onLoadMore: () => void
}

export interface IFormInput {
  zipcode: string
  city: string
}

export const PanelPointRelaisView = ({
  className,
  PointsRelais,
  onLoadMore,
}: Props) => {
  const dispatch = useDispatch()
  // fonction non utilisé  => click sur le panneaux pour gerer la carte
  const setReduxState = (
    IdPointRelais: string,
    pointLivraison: PointRelais
  ) => {
    dispatch(changeActivePointLivraison(IdPointRelais, pointLivraison))
  }

  const { selected: activePointLivraison } = useSelector(
    state => state.activePointLivraisonReducer
  )

  useEffect(() => {
    if (isDomAvailable()) {
      const element = document.getElementsByClassName(activePointLivraison)[0]
      element && element.scrollIntoView()
    }
  }, [activePointLivraison])

  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  return (
    <Wrapper className={classNames}>
      <Wrapper2 className={classNames}>
        {PointsRelais.map((item: PointRelais) => {
          return (
            <ItemPointRelaisView
              className={item.IdPointRelais}
              key={uuidv4()}
              data={item}
              setReduxState={setReduxState}
            ></ItemPointRelaisView>
          )
        })}
      </Wrapper2>
    </Wrapper>
  )
}

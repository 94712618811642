import React, { useState, useEffect } from "react"
import { Icon } from "./../../../../common/Icon"
import { isDomAvailable } from "./../../../../../lib/util"
import { useDetectDevice } from "./../../../../../lib/useDetectDevice"
import {
  AddressTypeEnum,
  useDefaultUserAddress,
  useDeleteUserAddresss,
} from "@saleor/sdk"
import {
  Wrapper,
  Title,
  RecapInfo,
  Row,
  Text,
  Button,
  Menu,
  Entry,
  RecapTitle,
} from "./Address.style"

interface Props {
  address: any
  withRow?: boolean
  className?: string
}

export function AddressView({ address, className, withRow = true }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  const [displayMenu, setDisplayMenu] = useState(false)
  const [menuPosition, setMenuPosition] = useState({})
  const [setDefault] = useDefaultUserAddress()
  const [deleteUserAddress] = useDeleteUserAddresss()

  const setDefaultShipping = e => {
    setDefault({
      id: address.id,
      type: AddressTypeEnum.SHIPPING,
    })
    setDisplayMenu(false)
  }

  const setDefaultBilling = e => {
    setDefault({
      id: address.id,
      type: AddressTypeEnum.BILLING,
    })
    setDisplayMenu(false)
  }

  const deleteAddress = e => {
    deleteUserAddress({
      addressId: address.id,
    })
    setDisplayMenu(false)
  }

  function getPosition(e) {
    var left = e.pageX
    var top = e.clientY
    if (e.pageX + 250 >= window.innerWidth) {
      left = e.pageX - (e.pageX + 300 - window.innerWidth)
    }
    if (e.clientY + 200 >= window.innerHeight) {
      top = e.clientY - (e.clientY + 200 - window.innerHeight)
    }
    return { top: top, left: left }
  }

  function disableScroll() {
    if (isDomAvailable()) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop)
      }
    }
  }

  function enableScroll() {
    if (isDomAvailable()) {
      window.onscroll = function () { }
    }
  }
  useEffect(() => {
    if (displayMenu) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [displayMenu])

  const getSidePosition = () => {
    if (isMobile) {
      return menuPosition.right + "px"
    } else {
      return menuPosition.left + "px"
    }
  }
  return (
    <Wrapper className={classNames}>
      {withRow && (
        <Row>
          <Title>
            {address?.isDefaultBillingAddress &&
              !address?.isDefaultShippingAddress &&
              "Adresse de facturation"}
            {!address?.isDefaultBillingAddress &&
              address?.isDefaultShippingAddress &&
              "Adresse de livraison"}
            {address?.isDefaultBillingAddress &&
              address?.isDefaultShippingAddress &&
              "Adresse par défaut"}
          </Title>
          <Button
            onClick={e => {
              setMenuPosition(getPosition(e))
              setDisplayMenu(true)
            }}
          >
            <Icon icon="th-list" size={20} className="icon" />
          </Button>
          {displayMenu && (
            <Menu
              className={isMobile && "mobile"}
              onClickOutside={e => {
                setDisplayMenu(false)
              }}
              width="250px"
              height="10%"
              top={menuPosition.top + "px"}
              left={getSidePosition()}
              withCloseBtn={false}
            >
              <Entry onClick={setDefaultShipping}>
                Définir comme adresse de livraison
              </Entry>
              <Entry onClick={setDefaultBilling}>
                Définir comme adresse de facturation
              </Entry>
              <Entry onClick={deleteAddress}>Supprimer</Entry>
            </Menu>
          )}
        </Row>
      )}
      <RecapInfo>
        <Text>
          {`${address?.firstName} ${address?.lastName}`}
        </Text>
        <Text>{address?.streetAddress1}</Text>
        <Text>
          {address?.postalCode} {address?.city} {address?.countryArea && address?.countryArea}
        </Text>
        <Text>
          {address?.country?.country}
        </Text>
        {address?.streetAddress2 && (<Text>{`Tel : ${address?.streetAddress2}`}</Text>)}
      </RecapInfo>
    </Wrapper>
  )
}

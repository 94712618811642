import { gql } from "@apollo/client"

export const CONFIRM_ACCOUNT = gql`
    mutation ConfirmAccount(
        $email: String!
        $token: String!
    ){
        confirmAccount(
            email: $email
            token: $token
        ){
            user{
                email
            }
        }
    }
`
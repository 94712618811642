import React from "react"
import { PointRelais } from "../../../../../../lib/map"
import { MapPointRelaisView } from "./MapPointRelais.view"

interface Props {
  mapSize: { h: string; w: string }
  className?: string
  getRelais: () => Promise<PointRelais[]>
}

export function MapPointRelaisContainer({
  className,
  mapSize,
  getRelais,
}: Props) {
  const mapSettings = {
    className: className,
    mapSize: mapSize,
  }
  return <MapPointRelaisView mapSettings={mapSettings} getRelais={getRelais} />
}

import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { setAuthToken, useSetPassword } from "@saleor/sdk"
import {
  Input,
  Submit,
  Error as ErrorMessage,
  PasswordInput,
} from "./ResetPassword.style"
import { navigate } from "gatsby"

interface IFormInput {
  oldPassword: string
  password: string
  password_repeat: string
}

export function ResetPasswordView() {
  const [isError, setError] = useState("")

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>()

  const password = useRef({})
  password.current = watch("password", "")

  const [tokenError, setTokenError] = React.useState(false)
  const [passwordError, setPasswordError] = React.useState("")
  const [setPassword, { data, error: graphqlErrors }] = useSetPassword()

  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get("email")
  const token = urlParams.get("token")

  if (!email || !token) {
    navigate("/app/profile")
  }

  useEffect(() => {
    // verifier que l'opperation de reset mdp à marché
    if (data && data.setPassword && data.setPassword.token) {
      // le changement de mdp est ok : se connecter
      setAuthToken(data.setPassword.token)
      navigate("/app/profile")
    }
    if (
      graphqlErrors &&
      graphqlErrors.extraInfo &&
      graphqlErrors.extraInfo.userInputErrors
    ) {
      graphqlErrors.extraInfo.userInputErrors.forEach(error => {
        if (error.field === "token") setTokenError(true)
        else setTokenError(false)
        if (error.field === "password") setPasswordError(error.message)
        else setPasswordError("")
      })
    }
  }, [data, graphqlErrors])

  const onSubmit = async () => {
    setError("")
    try {
      let password = getValues("password")
      let result = null
      if (email && token && password) {
        result = await setPassword({
          email,
          password: password,
          token,
        })
      }
      if (result?.error) {
        throw new Error("can't change password")
      }

      alert("Mot de passe modifié")
    } catch (error) {
      console.error("can't restet password", error)
      alert(
        "Probleme lors de la reinitialisation du mot de passe, veuillez réessayer ultérieurement"
      )
      setError("Probleme lors de la reinitialisation du mot de passe")
    }
  }

  {/* Créer votre nouveau mot de passe */ }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PasswordInput register={register} />
      <Input
        placeholder="Confirmez le mot de passe *"
        type="password"
        {...register("password_repeat", {
          validate: value =>
            value === password.current ||
            "Les mots de passe sont différents",
        })}
      />
      {errors.password_repeat && (
        <ErrorMessage>{errors.password_repeat.message}</ErrorMessage>
      )}
      {errors.password && (
        <ErrorMessage>{errors.password.message}</ErrorMessage>
      )}
      {isError && <ErrorMessage>{isError}</ErrorMessage>}
      <Submit type="submit">VALIDER</Submit>
    </form>
  )
}

import React from "react"

import { OrdersView } from "./Orders.view"

interface Props {
  className?: string
}

export function OrdersContainer({ className }: Props) {
  return <OrdersView className={className} />
}

export const getSearchFromUrl = () => {
  var params = window.location.search.substr(1).split("&")
  var res = ""
  params.forEach(param => {
    var item = param.split("=")
    if (item[0] && item[0] == "r") {
      res = item[1]
    }
  })
  return res
}

export const getAttributesFromUrl = () => {
  var params = window.location.search.substr(1).split("&")
  var res = []
  params.forEach(param => {
    var item = param.split("=")
    if (item[0] && item[0] != "r" && item[0] != "" && item[0] != "after") {
      res.push({ slug: item[0], values: item[1].split("%3B")})
    }
  })
  return res
}

export const getRelativePath = () => {
  return window.location.pathname
}

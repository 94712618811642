import React, {useRef, useEffect} from 'react';

interface Props {
    data: any
}

export function Up2PayElementView({ data }: Props) {
    const myForm = useRef(null);

    useEffect(()=>{
        if(myForm.current){
            myForm.current.submit()
        }
    },[myForm])

    return (
        <form ref={myForm} method="POST" action={process.env.GATSBY_UP2PAY_URL} >
            <input type="hidden" name="PBX_SITE" value={data["PBX_SITE"]} />
            <input type="hidden" name="PBX_RANG" value={data["PBX_RANG"]} />
            <input type="hidden" name="PBX_IDENTIFIANT" value={data["PBX_IDENTIFIANT"]} />
            <input type="hidden" name="PBX_SOURCE" value={data["PBX_SOURCE"]} />
            <input type="hidden" name="PBX_TOTAL" value={data["PBX_TOTAL"]} />
            <input type="hidden" name="PBX_DEVISE" value={data["PBX_DEVISE"]} />
            <input type="hidden" name="PBX_CMD" value={data["PBX_CMD"]} />
            <input type="hidden" name="PBX_PORTEUR" value={data["PBX_PORTEUR"]} />
            <input type="hidden" name="PBX_RUF1" value={data["PBX_RUF1"]} />
            <input type="hidden" name="PBX_BILLING" value={data["PBX_BILLING"]} />
            <input type="hidden" name="PBX_SHOPPINGCART" value={data["PBX_SHOPPINGCART"]} />
            <input type="hidden" name="PBX_RETOUR" value={data["PBX_RETOUR"]} />
            <input type="hidden" name="PBX_HASH" value={data["PBX_HASH"]} />
            <input type="hidden" name="PBX_TIME" value={data["PBX_TIME"]} />
            <input type="hidden" name="PBX_HMAC" value={data["PBX_HMAC"]} />
        </form> 
    );
}

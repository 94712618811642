import React from "react"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { useAuth } from "@saleor/sdk"
import { v4 as uuidv4 } from "uuid"
import {
  Wrapper,
  Info,
  Title,
  Text,
  OngletWrapper,
  HistoriqueCommandeInfo,
  StyledIcon,
} from "./Orders.style"
import { Spinner } from "../../../common/Spinner"
import { GET_ORDER_BY_USER } from "./Orders.query"
import { useQuery } from "react-apollo"
import { defaultTheme } from "../../../../assets/themes/default"
import moment from "moment"

interface Props {
  className?: string
}
export function OrdersView({ className }: Props) {
  const { authenticated, tokenRefreshing, token } = useAuth()
  const isMobile = useDetectDevice()
  const color = defaultTheme.palette.secondary.main

  if (tokenRefreshing || !authenticated) return <Spinner />

  return (
    <OngletWrapper>
      <HistoriqueCommandeInfo>
        <StyledIcon icon="info" color={color} size={isMobile ? 60 : 100} />
        <Text className={isMobile ? "mobile" : ""}>
          Votre site a fait peau neuve, vous pouvez retrouver vos commandes
          passées avant le 15/09/2021 en appelant le Service Client au
          05.82.95.08.87
        </Text>
      </HistoriqueCommandeInfo>
      <LoadOrderForUser token={token} className={className} />
    </OngletWrapper>
  )
}

function LoadOrderForUser({ token, className }) {
  const isMobile = useDetectDevice()
  const { loading, error, data } = useQuery(GET_ORDER_BY_USER, {
    variables: { perPage: 10000, after: "" },
    pollInterval: 60000,
  })

  return (
    <Wrapper className={`className ${isMobile ? "mobile" : ""}`}>
      <Title>Date</Title>
      <Title className={isMobile ? "mobile" : ""}>N° Commande</Title>
      <Title className={isMobile ? "mobile" : ""}>Résumé</Title>
      <Title className={isMobile ? "mobile" : ""}>Nombre d'articles</Title>
      <Title>Montant</Title>
      <Title>Statut</Title>

      {loading ? (
        <Spinner />
      ) : (
          <ContentOrders
            error={error}
            orders={data.me.orders}
            isMobile={isMobile}
          />
          )}
    </Wrapper>
  )
}

function ContentOrders({ error, orders, isMobile }) {
  return orders?.edges?.map(edge => {
    const order = edge.node
    var statusTrad
    if (order.statusDisplay == "Draft") {
      statusTrad = "Brouillon"
    } else if (order.statusDisplay == "Unfulfilled") {
      statusTrad = "En traitement"
    } else if (order.statusDisplay == "Partially fulfilled") {
      statusTrad = "Partiellement complète"
    } else if (order.statusDisplay == "Fulfilled") {
      statusTrad = "Expédiée"
    } else if (order.statusDisplay == "Canceled") {
      statusTrad = "Annulée"
    }

    return (
      <React.Fragment key={uuidv4()}>
        <Info>{moment(order.created).format("DD/MM/YYYY")}</Info>
        <Info className={isMobile ? "mobile" : ""}>{order.number}</Info>
        <Info className={isMobile ? "mobile" : ""}>
          {order.lines.map(line => (
            <Info key={uuidv4()} className={isMobile && "mobile"}>
              {line?.variant?.product.name}
            </Info>
          ))}
        </Info>
        <Info className={isMobile ? "mobile" : ""}>{order.lines.length}</Info>
        <Info>
          {order.total?.gross.amount} {order.total?.gross.currency}
        </Info>
        <Info>{statusTrad}</Info>
      </React.Fragment>
    )
  })
}

import React from "react"
import { Router } from "@reach/router"
import { PageDashboard } from "./../components/specific/PageDashboard"
import { SaleorProvider } from "@saleor/sdk"
import { PrivateRoute } from "../components/common/PrivateRoute/PrivateRoute"
import { PageLogin } from "../components/specific/PageLogin"
import { PageCheckout } from "../components/specific/PageCheckout"
import { CheckoutStep, CHECKOUT_STEPS } from "../core/config"
import { PagePasswordAction } from "../components/specific/PagePasswordAction"

function getUrlCheckout(step: CheckoutStep) {
  const stepObject = CHECKOUT_STEPS.filter(x => x.step == step)[0]
  const currentState =
    stepObject || CHECKOUT_STEPS.filter(x => x.step == CheckoutStep.Base)[0]
  return currentState.link
}

const App = () => {
  return (
    <>
      <Router basepath="/app">
        <PageLogin path="/login" redirectUrl={"/app/profile"} />
        <PagePasswordAction path="/demand-password" type="demand" title="Réinitialisez votre nouveau mot de passe" />
        <PagePasswordAction path="/reset-password" type="reset" title="Créez votre nouveau mot de passe" />
        <PagePasswordAction path="/change-password" type="change" title="Changez votre mot de passe" />
        <PrivateRoute path="/profile" component={PageDashboard} />
        <PageCheckout path={getUrlCheckout(CheckoutStep.Base)} />
        <PrivateRoute
          path={getUrlCheckout(CheckoutStep.Shipping)}
          component={PageCheckout}
        />
        <PrivateRoute
          path={getUrlCheckout(CheckoutStep.Payment)}
          component={PageCheckout}
        />

        <PrivateRoute
          path={getUrlCheckout(CheckoutStep.PaymentFinal)}
          component={PageCheckout}
        />
      </Router>
    </>
  )
}
export default App

import React, { useState } from "react"
import { useEffect } from "react"
import { PointRelais } from "../../../../../../lib/map"
import { PanelPointRelaisView } from "./PanelPointRelais.view"

interface Props {
  className?: string
  getRelais: () => Promise<PointRelais[]>
}
async function dataFetch(
  getRelais: () => Promise<PointRelais[]>,
  setLstPointRelaist: Function
) {
  const data = await getRelais()
  setLstPointRelaist(data)
}

export function PanelPointRelaisContainer({
  className,
  getRelais,
}: Props) {
  const [LstPointRelais, setLstPointRelaist] = useState(
    new Array<PointRelais>()
  )
  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    dataFetch(getRelais, setLstPointRelaist)
  }, [])

  return (
    <PanelPointRelaisView
      PointsRelais={LstPointRelais}
      className={className}
    />
  )
}

import React from "react"

import { PageDashboardView } from "./PageDashboard.view"

interface Props {
  className?: string
}

export function PageDashboardContainer({ className }: Props) {
  return <PageDashboardView className={className} />
}

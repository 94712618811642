import React, { useEffect, useState, useRef } from "react"
import { useCheckoutQuery } from "./PageCheckout.query"
import { PageCheckoutView } from "./PageCheckout.view"
import { CheckoutStep, CHECKOUT_STEPS, IStepInfo } from "../../../core/config"
import { ProductList } from "./ProductList"
import { CheckoutPayment } from "./CheckoutPayment"
import { CustomerIdentity } from "./CustomerIdentity"
import { getActiveStep } from "../../../helper/getActiveStep"
import { FormulaireLivraison } from "./FormulaireLivraison"
import {
  StyledProductList,
  WrapperView,
  Line,
  Icon,
  WrapperCheckout,
  Wrapper03,
  Wrapper04,
  Wrapper05,
  BackgroundForm,
  Background,
  Step,
  StepTitle,
  Content,
  Action,
  Column,
  InfoCheckout,
  OrderTitle,
  OrderInfo,
} from "./PageCheckout.style"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { Media } from "gatsby-plugin-fresnel"
import { useClickedOutside } from "../../../lib/useClickedOutside"
import { useCheckout, useAuth } from "@saleor/sdk"
import { CheckoutFinal } from "./CheckoutFinal"
import { getAvailableShippingMethodsFiltered } from "./FormulaireLivraison/PropFormAdresse"
import { ICheckout } from "@saleor/sdk/lib/api/Checkout/types"
import { gtagger } from "../../../helper/gtag"
import { Title, WrapperTitle } from "./ProductList/ProductList.style"

function getStepView(stepInfo: IStepInfo) {
  const [isOpen, setIsOpen] = useState(0)
  const [paymentIsOpen, setPaymentIsOpen] = useState(false)
  const [adressWasClicked, setAdressWasClicked] = useState(false)
  const wrapperRef = useRef(null)
  const { clickedOutside, setElementRef } = useClickedOutside()
  const ref = setElementRef()
  const { checkout, payment, availableShippingMethods, setShippingMethod } =
    useCheckout()
  const { user } = useAuth()
  useEffect(() => {
    if (clickedOutside) {
      setIsOpen(0)
    }
  })

  const linkStyles = {
    textDecoration: "none",
  }

  interface StepChoiceProps {
    order: number
    active: number
    title: string
    action: Function
    content?: string
    label?: string
    isEditable?: boolean
  }
  const StepChoice: React.FC<StepChoiceProps> = ({
    order,
    title,
    action,
    content,
    active = "",
    label,
    isEditable = true,
  }) => {
    const className = content?.length > 0 ? "valid" : ""
    const isMobile = useDetectDevice()
    const classNames = (className && className) + " " + (isMobile && "mobile")
    const sendTagStep = () => {
      gtagger("checkout step", { step: title })
    }

    const handleClick = () => {
      sendTagStep()
      action()
    }

    return (
      <Step className={classNames}>
        <Column>
          <StepTitle>{title}</StepTitle>
          <Content className={classNames}>{content}</Content>
        </Column>
        {action && (
          <Action onClick={handleClick}>
            {label}
            {isEditable && (
              <Icon
                className={active && "active"}
                icon="arrow_next"
                size={12}
                color="black"
              />
            )}
          </Action>
        )}
      </Step>
    )
  }

  const getShippingAddressString = () => {
    let res = ""
    if (checkout?.shippingAddress) {
      res +=
        checkout?.shippingAddress.firstName +
        " " +
        checkout?.shippingAddress.lastName +
        ", "
      if (checkout?.shippingAddress.companyName) {
        res += checkout?.shippingAddress.companyName + ", "
      }
      res += checkout?.shippingAddress.streetAddress1 + ", "
      if (checkout?.shippingAddress.streetAddress2) {
        res += checkout?.shippingAddress.streetAddress2 + ", "
      }
      res += checkout?.shippingAddress.postalCode + ", "
      res += checkout?.shippingAddress.city + ", "
      res += checkout?.shippingAddress.country?.country
      if (!checkout.shippingMethod) {
        availableShippingMethods?.map(item => {
          if (
            item.name == "FRANCE COLISSIMO" ||
            item.name == "Livraison à domicile"
          ) {
            setShippingMethod(item.id)
          }
        })
      }
    }
    return res
  }

  const getPaymentString = () => {
    let res = ""
    if (payment?.creditCard?.lastDigits) {
      res = "#### #### #### " + payment?.creditCard?.lastDigits
    }
    if (payment?.gateway == "mirumee.payments.dummy") {
      res = "Chèque ou virement"
    }
    return res
  }
  const isFormSelectAddressClicker = useRef(false)
  const isMobile = useDetectDevice()
  switch (stepInfo.step) {
    case CheckoutStep.Base: // necessite un panier redux plein
      return <CustomerIdentity />
    case CheckoutStep.Shipping:
      return (
        <>
          <Media lessThan="md">
            <WrapperView>
              <ProductList />
              <InfoCheckout className={isMobile && "mobile"}>
                <WrapperTitle>
                  <Title>Livraison & paiement</Title>
                </WrapperTitle>
                <StepChoice
                  order={2}
                  title="Adresse de livraison"
                  action={() => setIsOpen(1)}
                  content={
                    ShouldForceSetShippingMethode(
                      availableShippingMethods,
                      checkout
                    ) && getShippingAddressString()
                  }
                  active={isOpen == 1}
                  label="Je sélectionne mon adresse"
                />
                {isOpen == 1 && (
                  <BackgroundForm className={isMobile && "mobile"}>
                    <FormulaireLivraison
                      stepInfo={stepInfo}
                      setOpen={e => {
                        setAdressWasClicked(true)
                        setIsOpen(0)
                      }}
                    />
                  </BackgroundForm>
                )}
                <StepChoice
                  order={3}
                  title="Paiement"
                  action={() =>
                    checkout &&
                    checkout.shippingAddress &&
                    checkout.shippingMethod &&
                    setIsOpen(2)
                  }
                  content={getPaymentString()}
                  isEditable={false}
                />
                <CheckoutPayment />
              </InfoCheckout>
            </WrapperView>
          </Media>
          <Media greaterThanOrEqual="md">
            <WrapperCheckout>
              <OrderTitle>
                Votre commande {user?.firstName + " " + user?.lastName}
              </OrderTitle>
              <OrderInfo>
                <InfoCheckout>
                  <WrapperTitle>
                    <Title>Livraison & paiement</Title>
                  </WrapperTitle>
                  <StepChoice
                    order={2}
                    title="Adresse de livraison"
                    action={() => setIsOpen(1)}
                    content={
                      ShouldForceSetShippingMethode(
                        availableShippingMethods,
                        checkout
                      ) && getShippingAddressString()
                    }
                    active={isOpen == 1}
                    label="Je sélectionne mon adresse"
                  />
                  {isOpen == 1 && (
                    <Background>
                      <BackgroundForm>
                        <FormulaireLivraison
                          stepInfo={stepInfo}
                          setOpen={e => {
                            setAdressWasClicked(true)
                            setIsOpen(0)
                          }}
                        />
                      </BackgroundForm>
                    </Background>
                  )}
                  <Line />
                  <StepChoice
                    order={3}
                    title="Paiement"
                    action={() =>
                      checkout &&
                      checkout.shippingAddress &&
                      checkout.shippingMethod
                    }
                    content={getPaymentString()}
                    isEditable={false}
                  />
                  <CheckoutPayment />
                </InfoCheckout>
                <ProductList />
              </OrderInfo>
            </WrapperCheckout>
          </Media>
        </>
      )
    case CheckoutStep.Payment: // necessite une adresse de shipping
      return (
        <>
          <Media lessThan="md">
            <WrapperView className={isMobile && "mobile"}>
              <StyledProductList>
                <ProductList />
              </StyledProductList>
              <FormulaireLivraison stepInfo={stepInfo} />
            </WrapperView>
          </Media>
          <Media greaterThanOrEqual="md">
            <WrapperCheckout>
              <OrderTitle>
                Votre commande {user?.firstName + " " + user?.lastName}
              </OrderTitle>
              <OrderInfo>
                <InfoCheckout>
                  <WrapperTitle>
                    <Title>Livraison & paiement</Title>
                  </WrapperTitle>
                  <StepChoice
                    order={2}
                    title="Adresse de livraison"
                    action={() => setIsOpen(1)}
                    content={
                      ShouldForceSetShippingMethode(
                        availableShippingMethods,
                        checkout
                      ) && getShippingAddressString()
                    }
                    active={isOpen}
                    label="Je sélectionne mon adresse"
                  />
                  <Wrapper03
                    onClick={() => {
                      setIsOpen(!isOpen)
                      isFormSelectAddressClicker.current = true
                    }}
                  >
                    <Wrapper04>2</Wrapper04>
                    <Wrapper05>Livraison</Wrapper05>
                  </Wrapper03>
                  {isOpen && <FormulaireLivraison stepInfo={stepInfo} />}
                  <Line />
                  <StepChoice
                    order={3}
                    title="Paiement"
                    action={() => setPaymentIsOpen(!paymentIsOpen)}
                    content={getPaymentString()}
                    active={isOpen}
                  />
                  {paymentIsOpen && <CheckoutPayment />}
                </InfoCheckout>
                <ProductList />
              </OrderInfo>
            </WrapperCheckout>
          </Media>
        </>
      )
    case CheckoutStep.PaymentFinal: // necessite  un payment  ok
      return <CheckoutFinal className={isMobile && "mobile"} />
  }
}

export function PageCheckoutContainer() {
  const data = useCheckoutQuery()
  const CurrentStepInfo = getActiveStep(CHECKOUT_STEPS)
  return (
    <PageCheckoutView
      data={data}
      ActiveCheckoutStep={getStepView(CurrentStepInfo)}
    />
  )
}

// verifie si on dois forcer le clic sur la modal d'adresse (l'adresse selectionner n'est pas dans les adresse filtré )
function ShouldForceSetShippingMethode(
  availableShippingMethods: any[],
  checkout: ICheckout
) {
  if (!availableShippingMethods?.length || !checkout) return false

  let availableShippingMethodsFiltered = getAvailableShippingMethodsFiltered(
    availableShippingMethods
  )
  const idSelected = checkout?.shippingMethod?.id
  return (
    availableShippingMethodsFiltered.findIndex(i => i.id == idSelected) >= 0
  )
}

import styled from "styled-components"

export const Input = styled.input`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: 20px;
  margin: 10px;
  &:focus{
    outline:none;
  }
`

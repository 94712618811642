import { useAuth } from "@saleor/sdk"
import React, { useEffect } from "react"
import { fixPrice } from "../../../helper/fixedPrice"
import { gtagger } from "../../../helper/gtag"
import { Link } from "../../common/Link"
import { Spinner } from "../../common/Spinner"
import { useDetectDevice } from "./../../../lib/useDetectDevice"
import {
  Wrapper,
  Title,
  Subtitle,
  SousWrapper,
  MidleParagraph,
  ButtomP,
  PopUp,
  InfoWrapper,
  Label,
  Rowo,
  Text,
  Texto,
  MiniTitle,
  Ligne,
} from "./PopUpThanksForOrder.style"

interface Props {
  className?: string
  error?: any
  orderNumber?: number
  paymentStatus?: string
  totalPrice?: any
}

export function PopUpThanksForOrderView({
  className,
  error,
  orderNumber,
  paymentStatus,
  totalPrice,
}: Props) {
  const { user } = useAuth()
  const isMobile = useDetectDevice()

  useEffect(()=>{ 
    if(orderNumber && totalPrice){
      gtagger("checkout-final", { "commande": orderNumber, "prix": totalPrice }) 
    }
  },[orderNumber , totalPrice]) 
  if (error) {
    return (
      <Wrapper className={className}>
        <PopUp>
          <Title>Erreur lors de votre commande</Title>
          <Subtitle>
            Une erreur est survenue lors de la finalisation de votre Commande
          </Subtitle>
          <MidleParagraph>
            Grandes Marques vous présente ses excuses pour ce désagrément.
            N'hésitez pas à nous contacter par email pour plus d'informations ou
            pour être accompagné(e) afin d'effectuer votre commande.
          </MidleParagraph>
        </PopUp>
      </Wrapper>
    )
  }
  if (!paymentStatus) return <Spinner></Spinner>

  return (
    <Wrapper className={className}>
      <PopUp>
        <Title>Merci pour votre commande</Title>
        <Subtitle>Commande numéro {orderNumber}</Subtitle>
        {paymentStatus == "NOT_CHARGED" && (
          <Subtitle>
            Montant du paiement à envoyer: {fixPrice(totalPrice?.gross.amount)}
            {totalPrice?.gross.currency}
          </Subtitle>
        )}
        <MidleParagraph>
          Grandes Marques vous remercie de votre commande, elle sera enregistrée
          dès réception de votre paiement. Un message de confirmation vous sera
          envoyé automatiquement à votre adresse e-mail :{user?.email}.
        </MidleParagraph>
        <ButtomP>
          Rendez-vous sur &nbsp;<Link link={"/app/profile"}>votre compte</Link>
          &nbsp; pour suivre votre commande.
        </ButtomP>
      </PopUp>
      {paymentStatus == "NOT_CHARGED" && (
        <div>
          <Texto>
            Vous avez choisi de payer par chèque ou virement bancaire, merci
            d'envoyer votre paiement en utilisant les informations ci-dessous:
          </Texto>
          <SousWrapper className={isMobile && "mobile"}>
            <ChequeElement />
            <TransferElement />
          </SousWrapper>
        </div>
      )}
    </Wrapper>
  )
}

const ChequeElement = () => {
  const isMobile = useDetectDevice()
  return (
    <InfoWrapper className={isMobile && "mobile"}>
      <Label>
        <MiniTitle>
          Pour le paiement par chèque, veuillez envoyer votre chèque à l'adresse
          suivante :
        </MiniTitle>
        <Ligne>GRANDES MARQUES</Ligne>
        <Ligne>210, Avenue de l’Industrie</Ligne>
        <Ligne>31660 BESSIERES</Ligne>
        <Ligne>FRANCE</Ligne>
      </Label>
    </InfoWrapper>
  )
}

const TransferElement = () => {
  const isMobile = useDetectDevice()
  return (
    <InfoWrapper className={isMobile && "mobile"}>
      <Label>
        <MiniTitle>
          Pour le paiement par virement, veuillez effectuer votre virement sur
          le compte suivant:
        </MiniTitle>
        <Rowo className={isMobile && "mobile"}>
          <Text>IBAN:</Text>
          <Text>FR76 1310 6005 0020 0000 8423 728</Text>
        </Rowo>
        <Rowo className={isMobile && "mobile"}>
          <Text>SWIFT:</Text>
          <Text> AGRIFRPP831</Text>
        </Rowo>
        <Rowo className={isMobile && "mobile"}>
          <Text>Code Etablissement:</Text>
          <Text> 13 106</Text>
        </Rowo>
        <Rowo className={isMobile && "mobile"}>
          <Text>Code Guichet:</Text>
          <Text> 00 500</Text>
        </Rowo>
        <Rowo className={isMobile && "mobile"}>
          <Text>Numéro de compte:</Text>
          <Text> 20000084237</Text>
        </Rowo>
        <Rowo className={isMobile && "mobile"}>
          <Text>Clé RIB:</Text>
          <Text> 28</Text>
        </Rowo>
        <Rowo className={isMobile && "mobile"}>
          <Text>Nom et adresse du titulaire:</Text>
        </Rowo>
        <Text className={isMobile && "mobile"}>SARL MAT</Text>
        <Text className={isMobile && "mobile"}>
          PARC ECONOMIQUE DU TRIANGLE
        </Text>
        <Text className={isMobile && "mobile"}>210 AVENUE DE L'INDUSTRIE</Text>
        <Text className={isMobile && "mobile"}>31 660 BESSIERES</Text>
      </Label>
    </InfoWrapper>
  )
}

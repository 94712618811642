import React from "react"
import PropTypes from "prop-types"
import { TileLayer } from "react-leaflet"

import { StyledMapContainer } from "./Map.style"
import { useConfigureLeaflet } from "../../../../../../../hooks"

const DEFAULT_MAP_SERVICE = "OpenStreetMap"

const MapView = (props: any) => {
  const {
    children,
    className,
    defaultBaseMap = DEFAULT_MAP_SERVICE,
    ...rest
  } = props

  useConfigureLeaflet()

  const mapSettings = {
    ...rest,
  }

  return (

    <StyledMapContainer
      className={className}
      {...mapSettings}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {children}
    </StyledMapContainer>

  )
}

MapView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultBaseMap: PropTypes.string,
}

export default MapView

import React, { useState, useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  useAuth,
  useAccountUpdate,
  CountryCode,
  useCreateUserAddress,
  useDefaultUserAddress,
  AddressTypeEnum,
} from "@saleor/sdk"
import { CommonWrapper } from "../../../CommonWrapper"
import { SelectCountry } from "../../../SelectCountry"
import {
  Wrapper,
  Input,
  Submit,
  Error,
  Select,
  Wrappers,
  LabelFormResult,
  ListErrors,
  PasswordInput,
  ContainerLabelAccount,
} from "./UniqueStepWithoutConfirmation.style"

import { Input as InputConfirm } from "./../../../../specific/PagePasswordAction/ChangePassword/ChangePassword.style"

import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { getRelativePath } from "../../../../../utils"
import { navigate } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { useLazyQuery } from "@apollo/react-hooks"
import { GET_COUNTRY_AREAS } from "./UniqueStepWithoutConfirmation.query"
import { Spinner } from "../../../Spinner"

interface Props {
  redirectUrl?: string
  className?: string
}

interface IFormInput {
  email: string
  password: string
  password_confirm: string
  firstName: string
  lastName: string
  age: number
  country: CountryCode
  city: string
  streetAddress1: string
  streetAddress2?: string
  postalCode: string
  isActive?: boolean
  note: string
  companyName: string
  cityArea?: string
  countryArea?: string
  phone: string
}

export function UniqueStepWithoutConfirmationView({
  className,
  redirectUrl = "/",
}: Props) {
  const { user, authenticated, registerAccount, signIn } = useAuth()
  const [setAccountUpdate] = useAccountUpdate()
  const [setDefault] = useDefaultUserAddress()
  const [setAdressesUpdate] = useCreateUserAddress()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInput>()
  const [customError, setCustomError] = useState("")
  const [getCountryAreas, dynamicGetCountryAreas] =
    useLazyQuery(GET_COUNTRY_AREAS)
  const [countryAreas, setCountryAreas] = useState(null)
  const [postalCodeRegex, setPostalCodeRegex] = useState("/.*/gmi")
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useDetectDevice()
  const watchCountry = watch("country")
  const password_confirm = useRef({})
  password_confirm.current = watch("password", "")

  useEffect(() => {
    getCountryAreas({ variables: { countryCode: watchCountry } })
  }, [watchCountry])

  useEffect(() => {
    if (dynamicGetCountryAreas?.data) {
      setPostalCodeRegex(
        dynamicGetCountryAreas.data.addressValidationRules.postalCodeMatchers
      )
      setCountryAreas(
        dynamicGetCountryAreas.data.addressValidationRules.countryAreaChoices
      )
    }
    if (dynamicGetCountryAreas?.error) {
      setCountryAreas(null)
    }
  }, [dynamicGetCountryAreas])

  useEffect(() => {
    if (user && user.firstName && user.addresses.length > 0) {
      navigate(redirectUrl)
    }
  }, [user])

  const updateCustomerAddress = async data => {
    setIsLoading(true)
    let address = await setAdressesUpdate({
      input: {
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        city: data.city,
        cityArea: data.city,
        postalCode: data.postalCode,
        country: data.country,
        countryArea: data.countryArea,
        phone: "",
      },
    })
    if (!address) {
      setCustomError(
        customError +
          "\nErreur lors de la creation de l'adresse. Veuillez vérifier les informations saisies."
      )
    } else {
      await setAccountUpdate({
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      })
      await setDefault({
        id: address.data?.user.addresses[0].id,
        type: AddressTypeEnum.SHIPPING,
      })
      await setDefault({
        id: address.data?.user.addresses[0].id,
        type: AddressTypeEnum.BILLING,
      })
    }
    setIsLoading(false)
  }

  const getSelectCountryArea = () => {
    if (countryAreas?.length > 0) {
      return (
        <Select
          className={classMobile}
          placeholder="Etat/Province *"
          {...register("countryArea", {
            required: false,
          })}
          defaultValue={countryAreas[0].raw}
        >
          {countryAreas.map(item => (
            <option key={uuidv4()} value={item.raw}>
              {item.verbose}
            </option>
          ))}
        </Select>
      )
    }
    return null
  }

  const createAccount = async (data: IFormInput) => {
    let res = await registerAccount(
      data.email,
      data.password,
      process.env.GATSBY_FRONTEND_URL + getRelativePath()
    )
    if (res?.dataError?.error[0]) {
      if (res?.dataError?.error[0].code == "UNIQUE") {
        setCustomError(
          customError + "\nImpossible de créer le compte, compte déjà existant."
        )
      }
      if (res?.dataError?.error[0].code != "UNIQUE") {
        setCustomError(
          customError +
            "\nImpossible de créer le compte, une erreur est survenue."
        )
      }
    }
    return res
  }

  const onSubmit = async (data: IFormInput) => {
    let resSignIn = null
    setCustomError("")
    if (!authenticated) {
      resSignIn = await createAccount(data)
    }
    if (resSignIn?.dataError?.error[0]) {
      if (resSignIn.dataError.error[0].code == "INVALID_CREDENTIALS") {
        setCustomError(customError + "\nMot de passe incorrect.")
      }
    } else {
      resSignIn = await signIn(data.email, data.password)
      await updateCustomerAddress(data)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  let classMobile = isMobile ? "mobile" : ""
  return (
    <Wrapper className={className}>
      <CommonWrapper>
        <ContainerLabelAccount>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Wrappers className={classMobile}>
              <Input
                className={classMobile}
                placeholder="Email *"
                type="email"
                {...register("email", {
                  required: true,
                })}
              />
              <PasswordInput className={classMobile} register={register} />

              <InputConfirm
                className={isMobile && "mobile"}
                placeholder="Confirmez le mot de passe *"
                type="password"
                {...register("password_confirm", {
                  validate: value =>
                    value === password_confirm.current ||
                    "Les mots de passe sont différents",
                })}
              />
              <Input
                className={classMobile}
                placeholder="Nom *"
                {...register("lastName", {
                  required: true,
                })}
              />
              <Input
                className={classMobile}
                placeholder="Prénom *"
                {...register("firstName", {
                  required: true,
                })}
              />
              <Input
                className={classMobile}
                placeholder="Entreprise"
                {...register("companyName", {
                  required: false,
                })}
              />
              <Input
                className={classMobile}
                placeholder="Numéro et rue *"
                {...register("streetAddress1", { required: true })}
              />
              <Input
                className={classMobile}
                placeholder="Code Postal *"
                {...register("postalCode", {
                  required: true,
                  pattern: {
                    value: new RegExp(postalCodeRegex),
                    message: "Merci de vérifier le code postal",
                  },
                })}
              />
              <Input
                className={classMobile}
                placeholder="Ville *"
                {...register("city", { required: true })}
              />
              <SelectCountry register={register} />
              {getSelectCountryArea()}
              <Input
                className={classMobile}
                placeholder="Téléphone"
                {...register("streetAddress2", { required: false })}
              />
              <Submit type="submit">VALIDER</Submit>
            </Wrappers>
          </form>
          <LabelFormResult>
            <ListErrors>
              {errors.email && <Error>Email incorrect</Error>}
              {errors.password && <Error>{errors.password.message}</Error>}
              {errors.password_confirm && (
                <Error>Les mots de passe sont différents</Error>
              )}
              {errors.lastName && <Error>Nom incorrect</Error>}
              {errors.firstName && <Error>Prénom incorrect</Error>}
              {errors.streetAddress1 && <Error>Adresse incorrecte</Error>}
              {errors.postalCode && <Error>Code Postal incorrect</Error>}
              {errors.note && <p>{errors.note.message}</p>}
              {errors.city && <p>{errors.city.message}</p>}
              {customError && <Error>{customError}</Error>}
            </ListErrors>
          </LabelFormResult>
        </ContainerLabelAccount>
      </CommonWrapper>
    </Wrapper>
  )
}

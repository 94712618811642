var slugify = require("slugify")

export const getMetadata = metadata => {
  var res = []
  if (metadata) {
    metadata.map(item => {
      if (typeof item.value == "string" && item.value.match(";")) {
        res[item.key] = item.value.split(";")
      } else {
        res[item.key] = item.value
      }
    })
  }
  return res
}

export const getProductUrl = product => {
  var res = ""
  const meta = getMetadata(product.metadata)
  let brandSlug = "brandName"
  const productSlug = product.slug
  if (meta["zoho_brand"]) {
    brandSlug = slugify(meta["zoho_brand"], { lower: true })
  }
  res = "/p/" + brandSlug + "/" + productSlug
  return res
}

import styled from "styled-components"
import { Popin as PopinBase } from "../../../common/Popin"
import { Address as AddressBase } from "./Address"
import { Button as ButtonBase } from "../../../common/Button"

export const Wrapper = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: 2;
  box-sizing: border-box;
  /* font-family: ${({ theme }) => theme.typography.body.fontFamily}; */
  &.mobile {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
export const ListAdresses = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  &.mobile {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`

export const Address = styled(AddressBase)`
  margin: 10px;
`

export const AddBtn = styled(ButtonBase)`
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
  height: 60px;
  /* background-color: ${({ theme }) => theme.palette.primary.light}; */
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.button.homepage};
    color: ${({ theme }) => theme.palette.button.contrastText};
  }
`

export const Text = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 10px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Popin = styled(PopinBase)`
  left: 0px;
  &.mobile {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
`

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
`

import styled from "styled-components"
import { Button } from "../../../../../../common/Button"
import { Icon as IconBase } from "../../../../../../common/Icon"

import {
  CommonParagraph,
  CommonSubParagraph,
  CommonSubtitle,
} from "../../../../../../common/Texts"

export const PointRelaisButton = styled(Button)`
  height: auto;
  background-color: transparent;
  width: 100%;
  border-radius: 0 0 0 0;
  color: black;
`

export const Wrapper = styled(PointRelaisButton)`
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid black;
  border: 0.1rem solid;

  &:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    border-width: 0.5px;
  }
`
export const WrapperClicked = styled(PointRelaisButton)`
  border: 0.2rem solid;
  margin: 20px 0;
`

export const Title = styled(CommonSubtitle)`
  margin: 0;
  font-size: 20px;
`

export const Address = styled(CommonParagraph)`
  margin: 0;
  font: normal normal normal 14px/17px Poppins;
`

export const Horaires = styled(CommonSubParagraph)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
`

export const Test = styled.button`
  width: auto;
  height: auto;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  text-decoration: underline;
  background: none;
  border: none;
  align-content: center;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.info.fontSize};
`
export const Test1 = styled.button`
  width: 100%;
  height: 100%;
`
export const Test2 = styled.button`
  width: 100%;
  height: 100%;
`
export const SpaceOnText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`
export const Icon = styled(IconBase)`
  width: 30px !important;
  height: 30px !important;
  color: #C4AB88;
`

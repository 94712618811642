import styled from "styled-components"
import { CommonParagraph } from "./../Texts"
import { Input as InputBase } from "./../Input"
import { Icon as IconBase } from "./../Icon"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Info = styled(CommonParagraph)`
  width: 80%;
  padding-left: 1em;
  &.mobile {
    font-size: 0.8rem;
  }
`

export const Field = styled.div`
  display: flex;
  border: 1px solid black;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`

export const Input = styled(InputBase)`
  margin: 0;
  width: 80%;
  border: none;
  &.mobile {
    font-size: 1rem;
  }
`

export const Icon = styled(IconBase)`
  padding: 29px 20px;
`

import React, { useEffect, useState } from "react"
import { useAuth, useCheckout } from "@saleor/sdk"
import { useDetectDevice } from "./../../../../../lib/useDetectDevice"
import { changeshippingAdress } from "../../../../../entities/shippingAddress/shippingAdress.reducer"
import { IAddress } from "@saleor/sdk/lib/api/Checkout/types"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import {
  Address,
  Subtitle,
  AddressBtn,
  Popin,
  Wrapper,
  Wrapper01,
  AdressList,
} from "./CustomerShippingAddresses.style"
import { CreateAddressForm } from "./../../../../common/CreateAddressForm"

interface Props {
  shippingAsBilling: boolean
}

export const CustomerShippingAddressesView = ({ shippingAsBilling }: Props) => {
  const isMobile = useDetectDevice()
  const { setShippingAddress } = useCheckout()
  const { user } = useAuth()
  const { selected } = useSelector(state => state.shippingAdressReducer)
  const dispatch = useDispatch()
  const [displayAddAddressPopin, setDisplayAddAddressPopin] = useState(false)

  const handleSelect = (address: IAddress) => {
    dispatch(changeshippingAdress(address))
    setShippingAddress(address, user?.email)
  }

  useEffect(() => {
    setDisplayAddAddressPopin(false)
  }, [user?.addresses])

  if (shippingAsBilling && user?.defaultBillingAddress) {
    return <Address address={user?.defaultBillingAddress} withRow={false} />
  }

  return (
    <Wrapper>
      <Subtitle>Sélectionnez une adresse</Subtitle>
      <Wrapper01 className={isMobile && "mobile"}>
        {user?.addresses &&
          user.addresses.map((address: IAddress) => {
            return (
              <AdressList onClick={() => handleSelect(address)} key={uuidv4()}>
                <Address
                  address={address}
                  isSelected={selected?.id === address.id}
                  withRow={false}
                  className={isMobile && "mobile"}
                />
              </AdressList>
            )
          })}
      </Wrapper01>
      <AddressBtn onClick={e => setDisplayAddAddressPopin(true)}>
        Choisir une autre adresse
      </AddressBtn>
      {displayAddAddressPopin && (
        <Popin
          className={isMobile && "mobile"}
          onClickOutside={e => {
            setDisplayAddAddressPopin(false)
          }}
          width="50%"
          height="auto"
          title="Nouvelle adresse"
        >
          <CreateAddressForm
            closeAction={e => {
              setDisplayAddAddressPopin(false)
            }}
            redirectUrl="/app/checkout/shipping"
          />
        </Popin>
      )}
    </Wrapper>
  )
}

import styled from "styled-components"
import { CommonParagraph, CommonSubtitle, CommonTitle } from "../../common/Texts"

export const Wrapper = styled.div`
  width: auto;
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: 20px;
  justify-content: center;
  align-content: center;
  align-items: center;
`

export const Title = styled(CommonTitle)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Subtitle = styled(CommonSubtitle)`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const SousWrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  &.mobile {
    flex-direction: column;
    align-items: center;
  }
`
export const Texto = styled(CommonParagraph)`
  text-align: center;
`

export const MidleParagraph = styled(CommonParagraph)`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
`

export const ButtomP = styled(CommonParagraph)`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
`

export const PopUp = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
`

export const InfoWrapper = styled.div`
  margin-top: 2em;
  width: 50%;
  box-shadow: ${({ theme }) => theme.shadows[10]};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 2em;
  &.mobile {
    width: 75%;
  }
`

export const Rowo = styled.div`
  display: flex;
  &.mobile {
    padding-bottom: 5%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const Text = styled(CommonParagraph)`
  &:first-child {
    font-style: italic;
  }
  &.mobile {
    text-align: center;
  }
`

export const Label = styled.p`
  ${({ theme }) => theme.info};
  margin: 0;
  padding-bottom: 10%;
`

export const MiniTitle = styled(CommonSubtitle)`
  padding-bottom: 5%;
`

export const Ligne = styled(CommonParagraph)`
  display: grid;
  grid-template-rows: 1fr;
`

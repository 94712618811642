import React, { useRef, useEffect, useState } from "react"
import L, { LatLngExpression } from "leaflet"
import { useMap } from "react-leaflet"
import { promiseToFlyTo } from "../../../../../../lib"
import {
  IPosition,
  getCenterLocation,
  PointRelais,
} from "../../../../../../lib/map"
import { ZoneLivraisonMarker } from "./ContentMap/MarkerPointRelais/ZoneLivraisonMarkerLst"
import { StyledMap, StyledMarker } from "./ContentMap"
import icon from "./../../../../../../images/home 1.png"
interface Props {
  mapSettings: {}
  getRelais: () => Promise<PointRelais[]>
}
const DEFAULT_LOCATION: LatLngExpression = {
  lat: 48.856614,
  lng: 2.3522219,
}

const CENTER = DEFAULT_LOCATION
const DEFAULT_ZOOM = 5
const ZOOM = 13

const timeToZoom = 1000
const timeToOpenPopupAfterZoom = 500
const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000
const popupContentHello = `<p>Vous êtes ici ✋</p>`
const popupContentGatsby = `
  <div class="popup-gatsby">
    <div class="popup-gatsby-content">
      <p>Choisissez un point de livraison</p>
    </div>
  </div>
`

/**
 * MapEffect
 * @description This is an example of creating an effect used to zoom in and set a popup on load
 */

const MapEffect = ({ getRelaisFn, markerRef, setMarkersList }: any) => {
  const map = useMap()
  useEffect(() => {
    if (!markerRef.current || !map || !getRelaisFn) return
    ;(async function run() {
      const popup = L.popup({
        maxWidth: 800,
      })

      const { current: marker } = markerRef || {}
      const getRelais = (getRelaisFn ||= () => [])

      var location: IPosition = { ...DEFAULT_LOCATION }
      var lstMarker = []
      try {
        lstMarker = await getRelais()

        setMarkersList(lstMarker)
        location = await getCenterLocation(lstMarker)
        marker.setLatLng(location)
        popup.setLatLng(location)
      } catch (error) {
        return (
          <StyledMap zoom={DEFAULT_ZOOM} center={DEFAULT_LOCATION}></StyledMap>
        )
      }
      popup.setContent(popupContentHello)

      setTimeout(async () => {
        await promiseToFlyTo(map, {
          zoom: ZOOM,
          center: location,
        })
        marker.bindPopup(popup)
        setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom)
        setTimeout(
          () => marker.setPopupContent(popupContentGatsby),
          timeToUpdatePopupAfterZoom
        )
      }, timeToZoom)
    })()
  }, [map, markerRef])

  return null
}
export const MapPointRelaisView = ({ getRelais, mapSettings }: Props) => {
  const [lstMarker, setlstMarker] = useState({
    markers: new Array<PointRelais>(),
  })
  const markerRef = useRef()
  const setMarkersList = (lst: Array<PointRelais>) => {
    setlstMarker({ markers: lst })
  }

  return (
    <StyledMap {...mapSettings} zoom={DEFAULT_ZOOM} center={DEFAULT_LOCATION}>
      <MapEffect
        getRelaisFn={getRelais}
        markerRef={markerRef}
        setMarkersList={setMarkersList}
      />
      <StyledMarker
        ref={markerRef}
        position={CENTER}
        visible={lstMarker.markers.length > 0}
        icon={L.icon({
          iconUrl: icon,
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          popupAnchor: [-3, -10],
        })}
      />
      <ZoneLivraisonMarker lstMarker={lstMarker.markers}></ZoneLivraisonMarker>
    </StyledMap>
  )
}

import React from "react"
import { Wrapper } from "./UserInfo.style"
import { ChangePassword } from "../../PagePasswordAction/ChangePassword"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

interface Props {
  className?: string
}

export function UserInfoView({ className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  return (
    <Wrapper className={classNames}>
      <ChangePassword />
    </Wrapper>
  )
}

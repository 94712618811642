import React from "react"

import { AddressesView } from "./Addresses.view"

interface Props {
  className?: string
}

export function AddressesContainer({ className }: Props) {
  return <AddressesView className={className} />
}

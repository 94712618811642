import styled from "styled-components"
import { ImageWithLinkOrNot } from "../../../../common/ImageWithLinkOrNot"
import { CommonSubtitle, CommonParagraph } from "../../../../common/Texts"

export const Wrapper = styled.div`
  width: 95%;
  margin: auto;
  padding: 5px 0;
  border-bottom: 1px solid #d8d8d8;
  :last-child{
    border-bottom: none;
  }
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  display: flex;
  justify-content: space-between;
  gap: 17px;
  &.mobile {
    width: auto;
  }
`
export const WrapperProduct = styled.div`
  display: flex;
  gap: 17px;
  width: 330px;
`

export const ItemImage = styled(ImageWithLinkOrNot)`
  object-fit: cover;
  height: 100px;
  width: 100px;
  border: 1px solid #E5E5E5;
`

export const ColumnTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  &.mobile{
    justify-content: space-between;
  }
`

export const RowInfo = styled.div`
  display: flex;
  gap: 5px;
`

export const ProductName = styled(CommonParagraph)`
  font-size: 14px;
  &.mobile {
    margin: 0;
  }
`
export const ColumnVariant = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
`

export const VariantName = styled(CommonParagraph)`
  font-size: 13px;
  width: 50%;
  margin: 0;
`

export const Quantity = styled(CommonParagraph)`
  font-size: 14px;
  font-weight: bold;
`

export const ColumnPrice = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 70px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-weight: ${({ theme }) => theme.typography.body.fontWeight};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`

export const Price = styled(CommonSubtitle)`
  font-size: 16px;
  margin: 0;
`

export const Undiscount = styled(CommonSubtitle)`
  font-size: 12px;
  text-decoration-line: line-through;
  margin: 0;
`

import styled from "styled-components"
import { Icon } from "../../../common/Icon"
import { CommonTitle } from "../../../common/Texts"

export const OngletWrapper = styled.div``

export const Title = styled.div`
  width: 100%;
  padding-top: 15%;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  margin-left: 3px;
  margin-right: 3px;
  font-family: bold;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.subtitle.fontSize};
  &.mobile {
    display: none;
  }
`

export const Info = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  align-self: center;
  font-size: ${({ theme }) => theme.typography.subtitle.fontSize};
  &.mobile {
    display: none;
  }
`
export const HistoriqueCommandeInfo = styled(CommonTitle)`
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-left: 1em;
  margin-top: 2em;
  align-items: center;
  display: inline-flex;
  border-left: 6px solid ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
`
export const StyledIcon = styled(Icon)`
  padding-right: 0.5em;
  padding-left: 0.5em;
  fill: "#333";
`
export const Text = styled.div`
  line-height: 100%;
  margin-right: 1em;
  font-size: 0.5em;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-weight: ${({ theme }) => theme.typography.body.fontWeight};
  &.mobile {
    svg {
      font-size: 0.3em;
    }
  }
`
export const Wrapper = styled.div`
  display: grid;
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: 0.2fr 0.2fr auto 0.2fr 0.2fr 0.2fr;
  padding-top: 2%;
  text-align: center;
  justify-content: space-around;
  align-content: stretch;
  align-items: end;
  justify-items: center;
  grid-row-gap: 1.5em;

  &.mobile {
    min-width: 100vw;
    grid-template-columns: 1fr 1fr 1fr;
    ${Title}, ${Info} {
      font-size: 1em;
    }
  }

  ${Title}.mobile, ${Info}.mobile {
    min-width: 100vw;
  }
`

import { useStaticQuery, graphql } from "gatsby"

export const usePasswordActionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query PasswordActionQuery {
          strapiGmHeader {
            BandeauHeader {
              Logo {
                url
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
      }
    `
  )
  return data
}
import React from "react"
import { UniqueStepWithoutConfirmation } from "./UniqueStepWithoutConfirmation"
// import { StepOne } from "./StepOne"
// import { StepTwo } from "./StepTwo"
// import {getAttributesFromUrl} from "./../../../../utils/index"

export function CreateAccountFormContainer() {
  // const attributes = getAttributesFromUrl()
  // if(attributes.length){
  //   let args = {}
  //   attributes.map(item=>{
  //     args[item.slug] = item.values[0]
  //   })
  //   return <StepTwo email={decodeURIComponent(args.email)} token={args.token}/>
  // }
  // return <StepOne/>
  return <UniqueStepWithoutConfirmation/>
}

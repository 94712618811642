import React from "react"
import { Spinner } from "./../../../../common/Spinner"
import { isDomAvailable } from "../../../../../lib"
import {
  getMarkersLocation,
  IPointRelaisColissimo,
  PointRelais,
} from "../../../../../lib/map"
import { MapPointRelais } from "./MapPointRelais"
import { PanelPointRelais } from "./PanelPointRelais"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"

import {
  Wrapper,
  Test3,
  WrapperMap,
  WrapperPanelMap,
  Label,
} from "./SelectionPointRelais.style"

interface Props {
  shippingDate: Date
  countryCode: string
  address?: string
  zipCode?: string
  city?: string
}

export function SelectionPointRelaisView({
  zipCode,
  city,
  countryCode,
  address,
  shippingDate,
}: Props) {
  let markersLocationPromise = getMarkersLocation(
    shippingDate,
    countryCode,
    address,
    zipCode,
    city
  ).catch(() => {
    return null
  })

  const getRelais = async (): Promise<PointRelais[]> => {
    try {
      return (await markersLocationPromise).map(
        (x: IPointRelaisColissimo, index: number) => new PointRelais(x, index)
      )
    } catch (error) {
      return []
    }
  }

  if (!isDomAvailable()) {
    return <Spinner />
  }

  const isMobile = useDetectDevice()
  const className = isMobile && "mobile"
  return (
    <Wrapper className={className}>
      <Label>Choisissez votre point relais</Label>
      <WrapperPanelMap className={className}>
        <PanelPointRelais
          getRelais={getRelais}
        />
        <WrapperMap className={className}>
          <MapPointRelais
            getRelais={getRelais}
            mapSize={{ w: "100%", h: "250px" }}
          />
        </WrapperMap>
      </WrapperPanelMap>
    </Wrapper>
  )
}

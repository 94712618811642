import styled from "styled-components"
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet"
import MapView from "./Map.view"

export const StyledMapContainer = styled(MapContainer)`
  height: 400px !important;
  width: 100% !important;
`
export const StyledTileLayer = styled(TileLayer)``
export const StyledZoomControl = styled(ZoomControl)``
export const StyledMarker = styled(Marker)``
export const StyledMarkerUser = styled(Marker)``
export const StyledPopup = styled(Popup)``
export const StyledMapView = styled(MapView)``

import React from "react"

import { UserInfoView } from "./UserInfo.view"

interface Props {
  className?: string
}

export function UserInfoContainer({ className }: Props) {
  return <UserInfoView className={className} />
}

import styled from "styled-components"
import { ImageWithLinkOrNot } from "../../common/ImageWithLinkOrNot"

export const Wrapper = styled.div`
  width: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
`

export const Top = styled.div`
  width: 100%;
  padding-bottom: 2.5%;
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
`

export const Logo = styled(ImageWithLinkOrNot)`
  height: 100%;
  object-fit: contain;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  width: 40%;
  min-width: 20vw;
  align-self: center;
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: auto;
  padding: 2em;
  font-family: ${({ theme }) => theme.typography.info.fontFamily};
  background-color: ${({ theme }) => theme.palette.primary.light};
  justify-content: center;
  &.mobile {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
  }
`

export const Title = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
  font-size: 2em;
  margin-bottom: 2em;
  &.mobile {
    font-size: medium;
  }
`